<template>
    <div class="container-xxl py-3" style="min-height: 60px;">
        <div class="container mshopes-buyer-section" style="min-height: 60px;">
            <vue-horizontal class="g-2 p-0 buyer-section-row float-end" responsive>
                <section class=" buyer-item-wrap me-1" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#customers">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/buyerfeatures/cart.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body buyer-title">
                            <small>{{ $t('title.booking-online') }}</small>
                        </div>
                    </a>
                </section>
                <section class=" buyer-item-wrap me-1" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#customers">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/buyerfeatures/sale.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body buyer-title">
                            <small>{{ $t('title.sale-off') }}</small>
                        </div>
                    </a>
                </section>
                <section class=" buyer-item-wrap me-1" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#customers">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/buyerfeatures/debit.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body buyer-title">
                            <small>{{ $t('title.online-payment') }}</small>
                        </div>
                    </a>
                </section>
                <section class="buyer-item-wrap" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#customers">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/buyerfeatures/fast.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body buyer-title">
                            <small>{{ $t('title.fast-ship') }}</small>
                        </div>
                    </a>
                </section>
            </vue-horizontal>
        </div>
    </div>
</template>
<script>
import VueHorizontal from "vue-horizontal";
export default {
    name: "BuyerFeature",
    components: {
        VueHorizontal
    }
}
</script>
<style>
.buyer-feature-image {
    border-radius: 10px;
}
.buyer-item-wrap {
    display: block;
    max-width: 90px;
    max-height: 90px;
    border-right: 1px solid rgb(233, 229, 229);
    padding: 0 5px;
}
.buyer-title {
    text-transform: capitalize;
    line-height: 1.2;
    font-size: 14px;
}
</style>