<template>
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <!-- Page Header Start -->
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.shopping-cart')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-4">
                <div v-for="(prod, index) in carts" class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <div class="product-item">
                        <div class="position-relative bg-light overflow-hidden">
                            <img v-if="prod.feature_image != null" class="img-fluid w-100" :src="prod.feature_image" alt="NoImage">
                            <div class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">Moi</div>
                        </div>
                        <div class="text-center p-4">
                            <a class="d-block h5 mb-2" :href="'/san-pham/detail/'+prod.slug">{{ prod.name }}</a>
                            <span class="text-primary me-1">{{ $filters.capitalize($t('title.quantity')) }}: {{ prod.qty }}</span>
                            <br/>
                            <span v-if="prod != null" class="text-body">{{ prod.selling_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }} </span>
                        </div>
                        <div class="d-flex border-top">
                            <small class="w-50 text-center border-end py-2">
                                <a class="text-body" :href="'/san-pham/detail/'+prod.slug"><i class="fa fa-eye text-primary me-2"></i>{{ $filters.capitalize($t('action.detail')) }}</a>
                            </small>
                            <small class="w-50 text-center py-2">
                                <a class="text-body" href="javascript:;" @click="removeFromCart(prod)"><i class="fa fa-trash text-danger me-2"></i>{{ $filters.capitalize($t('action.delete')) }}</a>
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-left wow fadeInUp" data-wow-delay="0.1s">
                    <p><i class="fa fa-soild fa-dollar-sign text-secondary me-3"></i>{{ $filters.capitalize($t('order.total-money')) }}: <span class="text-secondary text-bold">{{ total.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span></p>
                </div>
                <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <div class="d-flex border-top">
                        <small class="w-100 text-center border-end py-2 p-1">
                            <a class="w-100 btn btn-secondary rounded-pill py-2 px-3 btn-sm" href="/booking"><i class="fa fa-shopping-cart text-white me-2"></i>{{ $filters.capitalize($t('action.booking')) }}</a>
                        </small>
                        <small class="w-100 text-center py-2 p-1">
                            <a class="w-100 btn btn-primary rounded-pill py-2 px-3 btn-sm" href="/san-pham"><i class="fa fa-cart-plus text-white me-2"></i>{{ $filters.capitalize($t('action.more-products')) }}</a>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NavbarIndex from '@/components/NavbarIndex.vue';
export default {
    name: "CartIndex",
    data(){
        return {
            carts: [],
            total: 0,
            search: {
                keywords: '',
                page: 1,
                pagesize: 30,
                total: 0
            }
        }
    },
    components: {
        NavbarIndex
    },
    created(){
        this.carts = this.$store.state.cart;
        console.log(this.$store.state);
    },
    methods: {
        removeFromCart(prod){
            this.$store.dispatch('addProductToCart', {id: prod.id, name: prod.name, qty: -prod.qty, selling_price: prod.selling_price, slug: prod.slug});
        },
        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        onSearch() {
            this.$router.push({path: '/san-pham', query: this.search});
        }
    },
    watch: {
    '$store.state.cart': {
        handler(newCart){
            if (Array.isArray(newCart)) {
                this.total = 0;
                for(var i = 0; i < newCart.length; i++){
                    this.total += Number(newCart[i].selling_price) * Number(newCart[i].qty);
                }
            }
            this.carts = newCart;
        },
        immediate: true,
        deep: true
    }
  }
};
</script>
<style>
</style>