import request from '@/utils/request'
function list(query){
    return request({
        url: '/banner?'+query,
        method: 'get'
    });
}

function detail(id){
    return request({
        url: '/banner/'+id,
        method: 'get'
    });
}
export {list, detail};