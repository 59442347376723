<template>
 <spinner-top v-if="$store.state.isLoading" />
  <nav-bar :news_categories="news_category" :product_categories="product_category" :contact_us="contact_us" :video_categories="video_categories" />
  <flash-message group="login"></flash-message>
  <flash-message group="cart"></flash-message>
  <flash-message group="order"></flash-message>
  <flash-message group="error"></flash-message>
  <flash-message group="register"></flash-message>
  <router-view />
  <footer-vue :contact_us="contact_us" />
</template>

<script>
import SpinnerTop from './components/Spinner.vue'
import NavBar from './components/Navbar.vue'
import FooterVue from './components/Footer.vue'
import {getAppConfig} from '@/api/appconfig'
import {list as listNews} from '@/api/news_category'
import {list as listProds} from '@/api/product_category'
import {list as listVideoCategories} from '@/api/video_category'
export default {
  name: 'App',
  data(){
    return {
      contact_us: {},
      news_category: [],
      product_category: [],
      video_categories: []
    }
  },
  components: {
    SpinnerTop,
    NavBar,
    FooterVue,
  },
  beforeMount(){
    this.$flashMessage.removeAll();
  },
  async created(){
    if (typeof this.$store.getters.csrf_token == 'undefined') {
      await this.$store.dispatch('csrf/getCsrf');
    }
    let res = await getAppConfig();
    if (res != null && res.status == 200 && res.data != null) {
      this.contact_us = JSON.parse(res.data.app_config.contact_us);
    }
    res = await listProds();
    if (res != null && res.status == 200 && res.data != null) {
      this.product_category = res.data.data;
    }
    res = await listNews();
    if (res != null && res.status == 200 && res.data != null) {
      this.news_category = res.data.data;
    }
    res = await listVideoCategories();
    if (res != null && res.status == 200 && res.data != null) {
      this.video_categories = res.data.data;
    }
  },
}
</script>
