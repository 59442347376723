<template>
    <!-- Page Header Start -->
    <div class="container-fluid mt-5 py-3 wow fadeIn border-top" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 mb-3 animated slideInDown d-none">{{ $filters.capitalize($t('title.contact-us')) }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.contact-us')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- Contact Start -->
    <div class="container-xxl py-3">
        <div class="container">
            <div class="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                <h4 class="display-7 mb-3">{{ $filters.capitalize($t('title.contact-us')) }}</h4>
                <p>{{ $filters.capitalize($t('contact.our-contact-information')) }}</p>
            </div>
            <div class="row g-5 justify-content-center">
                <div class="col-lg-5 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="bg-primary text-white d-flex flex-column justify-content-center h-100 p-5">
                        <h5 class="text-white">{{ $filters.capitalize($t('contact.call-us')) }}</h5>
                        <p class="mb-5"><i class="fa fa-phone-alt me-3"></i>{{ contact_us.phone }}</p>
                        <h5 class="text-white">{{ $filters.capitalize($t('contact.email')) }}</h5>
                        <p class="mb-5"><i class="fa fa-envelope me-3"></i>{{ contact_us.email }}</p>
                        <h5 class="text-white">{{ $filters.capitalize($t('title.address')) }}</h5>
                        <p class="mb-5"><i class="fa fa-map-marker-alt me-3"></i>{{ contact_us.address }}</p>
                        <h5 class="text-white">{{ $filters.capitalize($t('title.follow-us')) }}</h5>
                        <div class="d-flex pt-2">
                            <a class="btn btn-square btn-outline-light rounded-circle me-1" :href="contact_us.twitter"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-square btn-outline-light rounded-circle me-1" :href="contact_us.facebook"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-square btn-outline-light rounded-circle me-1" :href="contact_us.instagram"><i class="fab fa-instagram"></i></a>
                            <a class="btn btn-square btn-outline-light rounded-circle me-0" :href="contact_us.tiktok"><i class="fa fab fa-tiktok"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                    <p class="mb-4">{{ $filters.capitalize($t('contact.you-can-send-email-to-get-more-information')) }}</p>
                    <form>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" placeholder="Your Name">
                                    <label for="name">{{ $filters.capitalize($t('title.fullname')) }}</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="email" class="form-control" id="email" placeholder="Your Email">
                                    <label for="email">{{ $filters.capitalize($t('email.email')) }}</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="subject" placeholder="Subject">
                                    <label for="subject">{{ $filters.capitalize($t('contact.subject')) }}</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="Leave a message here" id="message" style="height: 200px"></textarea>
                                    <label for="message">{{ $filters.capitalize($t('contact.content')) }}</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-primary rounded-pill py-1 px-3 btn-sm" type="submit">{{ $filters.capitalize($t('action.send')) }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->


    <!-- Google Map Start -->
    <div class="container-xxl px-0 wow fadeIn" data-wow-delay="0.1s" style="margin-bottom: -6px;">
        <iframe class="w-100" style="height: 450px;"
            :src="contact_us.embedmap"
            frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
    <!-- Google Map End -->
</template>
<script>
import {getAppConfig} from '@/api/appconfig'
export default {
    name: "ContactUs",
    data(){
        return {
            contact_us: {}
        }
    },
    async created(){
        let res = await getAppConfig();
        if (res != null && res.status == 200 && res.data != null) {
            document.title = res.data.app_config.title;
            const descEl = document.querySelector('head meta[name="description"]');
            descEl.setAttribute('content', res.data.app_config.meta_description);
            const keywordscEl = document.querySelector('head meta[name="keywords"]');
            keywordscEl.setAttribute('content', res.data.app_config.keywords);
            this.contact_us = JSON.parse(res.data.app_config.contact_us);
        }
    }
};
</script>