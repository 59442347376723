import request from '@/utils/request'
function list(query){
    return request({
        url: '/product?'+query,
        method: 'get'
    });
}
function listTop(query){
    return request({
        url: '/home-product?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/product/'+id,
        method: 'get'
    });
}
function listByCategory(query, categoryId){
    return request({
        url: '/product-by-category/'+categoryId+'?'+query,
        method: 'get'
    });
}
function listByShop(query, shopId){
    return request({
        url: '/product-by-shop/'+shopId+'?'+query,
        method: 'get'
    });
}
function related(id){
    return request({
        url: '/product/'+id+'/related',
        method: 'get'
    });
}
export {list, detail, listTop, listByCategory, listByShop, related};