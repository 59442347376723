<template>
        <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <!-- Page Header Start -->
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/blog">{{ $filters.capitalize($t('title.blog')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ newsDetail.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- About Start -->
    <div class="container-fluid bg-icon py-3">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-md-12 wow fadeIn" data-wow-delay="0.1s">
                    <h1 class="display-6 mb-3">{{ newsDetail.name }}</h1>
                    <div class="text-muted border-top pt-4">
                        <small class="me-3"><i class="fa fa-user text-primary me-2"></i>{{ newsDetail.user != null ? newsDetail.user.name : '' }}</small>
                        <small class="me-3"><i class="fa fa-calendar text-primary me-2"></i>{{ (new Date(newsDetail.public_time)).toLocaleString() }}</small>
                        <small class="me-3"><i class="fa fa-heart text-danger me-2"></i>{{ like_number }}</small>
                        <small class="w-50 text-center py-2">
                            <a class="text-body" href="javascript:;" @click="likeVideo()"><i class="fa fa-regular fa-thumbs-up" :class="class_like">{{ $filters.capitalize($t('action.like')) }}</i></a>
                        </small>
                    </div>
                    <div v-if="newsDetail.news_news_categories != null" class="d-flex pt-2 mb-4">
                        <small v-for="(prod, index) in newsDetail.news_news_categories" class="text-center border-end px-2" v-bind:key="index">
                            <a class="text-body" :href="'/blog/'+prod.news_category.slug">{{ prod.news_category.name }}</a>
                        </small>
                    </div>
                    <div class="row">
                        <div class="form-group mb-2 col col-md-12">
                            <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('action.share')) }}: </span>
                            <a :href="'https://www.facebook.com/sharer/sharer.php?u=http://autoviet247.com/blog/chi-tiet/'+newsDetail.slug" type="button" class="btn btn-success me-2 btn-sm" target="_blank"><i class="fab fa-facebook"></i></a>
                            <a :href="'https://twitter.com/intent/tweet?text='+newsDetail.name+'&url=http://autoviet247.com/blog/chi-tiet/'+newsDetail.slug+'&hashtags='+newsDetail.keywords" type="button" class="btn btn-info me-2 btn-sm" target="_blank"><i class="fab fa-twitter"></i></a>
                        </div>
                    </div>
                    <img v-if="newsDetail.feature_image != null" class="img-fluid" :src="host_url + newsDetail.feature_image" alt="No image" >
                    <br/>
                    <p class="mb-0" v-html="newsDetail.description"></p>
                </div>
            </div>
            <div class="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                <h4 class="display-7 mb-3">{{ $filters.capitalize($t('title.related-news')) }}</h4>
            </div>
            <div class="row g-4">
                <div v-for="(item, index) in news" class="col-xl-2 col-lg-2 col-md-6 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <blog-item :item="item" />
                </div>
                <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm" href="/blog">{{ $filters.capitalize($t('action.more')) }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { detail, related, list } from '@/api/news';
import NavbarIndex from '@/components/NavbarIndex.vue';
import BlogItem from './components/BlogItem.vue';
import { api_backend } from '@/constants/configs';
import { add } from '@/api/like_news';
export default {
    name: "NewsDetail",
    data(){
        return {
            newsDetail: {
                user: {}
            },
            news: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
            like_number: 0,
            class_like: 'text-default',
            is_login: false,
            search: {
                keywords: '',
                page: 1,
                pagesize: 30
            }
        };
    },
    components: {
        NavbarIndex,
        BlogItem
    },
    async created(){
        let res = await detail(this.$route.params.slug);
        if (res != null && res.status == 200 && res.data != null ){
            this.newsDetail = res.data;
            document.title = this.newsDetail.name;
            const descEl = document.querySelector('head meta[name="description"]');
            descEl.setAttribute('content', this.newsDetail.meta_description);
            const keywordscEl = document.querySelector('head meta[name="keywords"]');
            keywordscEl.setAttribute('content', this.newsDetail.keywords);
            // set facebook feature
            const titleElFacebook = document.querySelector('head meta[property="og:title"]');
            if (titleElFacebook != null) {
                titleElFacebook.setAttribute('content', this.newsDetail.name);
            }
            const descElFacebook = document.querySelector('head meta[property="og:description"]');
            if (descElFacebook != null) {
                descElFacebook.setAttribute('content', this.newsDetail.meta_description);
            }
            const urlElFacebook = document.querySelector('head meta[property="og:url"]');
            if (urlElFacebook != null) {
                urlElFacebook.setAttribute('content', this.host_url+"blog/chi-tiet/"+this.newsDetail.slug);
            }
            const imageElFacebook = document.querySelector('head meta[property="og:image"]');
            if (imageElFacebook != null) {
                imageElFacebook.setAttribute('content', this.host_url + this.newsDetail.feature_image);
            }
            const imageElFacebookWidth = document.querySelector('head meta[property="og:image:width"]');
            if (imageElFacebookWidth != null) {
                imageElFacebookWidth.setAttribute('content', "437");
            }
            const imageElFacebookHeight = document.querySelector('head meta[property="og:image:height"]');
            if (imageElFacebookHeight != null) {
                imageElFacebookHeight.setAttribute('content', "437");
            }
            // set twitter feature
            const titleElTwitter= document.querySelector('head meta[name="twitter:title"]');
            if (titleElTwitter != null) {
                titleElTwitter.setAttribute('content', this.newsDetail.name);
            }
            const descElTwitter = document.querySelector('head meta[name="twitter:description"]');
            if (descElTwitter != null) {
                descElTwitter.setAttribute('content', this.newsDetail.meta_description);
            }
            const imageElTwitter = document.querySelector('head meta[name="twitter:image"]');
            if (imageElTwitter != null) {
                imageElTwitter.setAttribute('content', this.host_url + this.newsDetail.feature_image);
            }
            let resRelated = await related(this.newsDetail.id);
            if (resRelated != null && resRelated.status == 200 && resRelated.data != null && resRelated.data.data != null && resRelated.data.data.length > 0 ){
                this.news = resRelated.data.data;
            } else {
                let resList = await list('page=1&pagesize=4');
                if (resList != null && resList.status == 200 && resList.data != null ){
                    this.news = resList.data.data;
                }
            }
        }
    },
    methods: {
        async likeVideo() {
            if (!this.is_login) {
                this.$router.push({ path: '/login' });
                return;
            }
            if (this.newsDetail != null && this.newsDetail.id > 0) {
                let res = await add({
                    news_id: this.newsDetail.id
                });
                if (res != null && res.status == 200 && res.data != null) {
                    this.like_number = res.data.length;
                    if (res.data.length > 0) {
                        let found = res.data.filter(el => el.user_id == this.$store.state.user.id);
                        if (found && found.length > 0) {
                            this.class_like = 'text-success';
                        } else {
                            this.class_like = 'text-default';
                        }
                    } else {
                        this.is_like = 'text-default';
                    }
                    this.$flashMessage.show({
                        type: 'success',
                        title: this.$filters.capitalize(this.$t('title.success')),
                        message: this.$filters.capitalize(this.$t('title.save-success')),
                        time: 3000,
                        group: 'cart'
                    });
                } else {
                    this.$flashMessage.show({
                        type: 'error',
                        title: this.$filters.capitalize(this.$t('title.failure')),
                        message: this.$filters.capitalize(this.$t('title.save-failure')),
                        time: 3000,
                        group: 'cart'
                    });
                }
            }
        },
        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        onSearch() {
            this.$router.push({path: '/blog', query: this.search});
        }
    },
    watch: {
        '$store.state.islogin': {
            handler(newValue) {
                this.is_login = newValue;
            },
            immediate: true,
            deep: true
        }
    }
}
</script>