import request from '@/utils/request'
function list(query){
    return request({
        url: '/product-category?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/product-category/'+id,
        method: 'get'
    });
}
function listWithProducts(query) {
    return request({
        url: '/product-category-product?'+query,
        method: 'get'
    });
}
function listWithTopProducts(query) {
    return request({
        url: '/product-category-home-product?'+query,
        method: 'get'
    });
}
export {list, detail, listWithProducts, listWithTopProducts};