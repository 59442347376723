import request from '@/utils/request'
function getAppConfig(){
    return request({
      url: '/appconfig',
      method: 'get'
    });
}
function logout(){
  return request({
    url: '/logout',
    method: 'get'
  });
}
function checklogin(){
  return request({
    url: '/checklogin',
    method: 'get'
  });
}
export {getAppConfig, logout, checklogin};