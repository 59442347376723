<template>

    <form class="form-inline">

        <div class="row">

            <div class="form-group mb-2 col-md-4">

                <input type="text" class="form-control form-control-sm" :value="keywords" placeholder="Từ khóa" @change="changeKeyword">

            </div>

            <div class="form-group mb-2 col col-md-4">

                <button type="button" class="btn btn-primary me-2 btn-sm" @click="onSearch"><i class="fa fa-search text-white me-2"></i> {{ $filters.capitalize($t('action.search')) }}</button>

                <button type="button" class="btn btn-warning btn-sm" @click="onRefresh"><i class="fa fa-refresh text-white me-2"></i> {{ $filters.capitalize($t('action.refresh')) }}</button>

            </div>

        </div>

    </form>

</template>

<script>

export default {

    name: "FormSearch",

    data(){

        return {

        };

    },

    props: [

        'changeKeyword',

        'onSearch',

        'onRefresh',

        'keywords',

    ],

    methods: {

    }

};

</script>