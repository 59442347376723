import request from '@/utils/request'
function list(query){
    return request({
        url: '/news?'+query,
        method: 'get'
    });
}
function listByCategory(query, categoryId){
    return request({
        url: '/news-by-category/'+categoryId+'?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/news/'+id,
        method: 'get'
    });
}
function listTop(query){
    return request({
        url: '/news-top?'+query,
        method: 'get'
    });
}
function related(id){
    return request({
        url: '/news/'+id+'/related',
        method: 'get'
    });
}
export {list, detail, listByCategory, listTop, related};