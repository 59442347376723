const api_backend = 'backend';
const product_sorts = [
    {
        field: 'selling_price',
        label: 'asc-price',
        value: 'selling_price-ASC'
    },
    {
        field: 'selling_price',
        label: 'desc-price',
        value: 'selling_price-DESC'
    },
    {
        field: 'updated_at',
        label: 'asc-updated-at',
        value: 'updated_at-ASC'
    },
    {
        field: 'updated_at',
        label: 'desc-updated-at',
        value: 'updated_at-DESC'
    },
];
const news_sorts = [
    {
        field: 'updated_at',
        label: 'asc-updated-at',
        value: 'updated_at-ASC'
    },
    {
        field: 'updated_at',
        label: 'desc-updated-at',
        value: 'updated_at-DESC'
    },
];
const video_sorts = [
    {
        field: 'updated_at',
        label: 'asc-updated-at',
        value: 'updated_at-ASC'
    },
    {
        field: 'updated_at',
        label: 'desc-updated-at',
        value: 'updated_at-DESC'
    },
];
const shop_sorts = [
    {
        field: 'updated_at',
        label: 'asc-updated-at',
        value: 'ASC'
    },
    {
        field: 'updated_at',
        label: 'desc-updated-at',
        value: 'DESC'
    },
];
export {api_backend, product_sorts, news_sorts, video_sorts, shop_sorts};