<template>
            <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <!-- Page Header Start -->
    <div class="container-fluid py-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/short-video">{{ $filters.capitalize($t('title.short-video')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ newsDetail.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- About Start -->
    <div class="container-fluid bg-icon py-3">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-md-12 wow fadeIn" data-wow-delay="0.1s">
                    <h1 class="display-6 mb-3">{{ newsDetail.name }}</h1>
                    <div class="text-muted border-top pt-4">
                        <small class="me-3"><i class="fa fa-user text-primary me-2"></i>{{ newsDetail.user != null ? newsDetail.user.name : '' }}</small>
                        <small class="me-3"><i class="fa fa-calendar text-primary me-2"></i>{{ (new Date(newsDetail.public_time)).toLocaleString() }}</small>
                        <small class="me-3"><i class="fa fa-heart text-danger me-2"></i>{{ like_number }}</small>
                        <small class="w-50 text-center py-2">
                            <a class="text-body" href="javascript:;" @click="likeVideo()"><i class="fa fa-regular fa-thumbs-up" :class="class_like">{{ $filters.capitalize($t('action.like')) }}</i></a>
                        </small>
                    </div>
                    <div v-if="newsDetail.news_news_categories != null" class="d-flex pt-2 mb-4">
                        <small v-for="(prod, index) in newsDetail.news_news_categories" class="text-center border-end px-2" v-bind:key="index">
                            <a class="text-body" :href="'/short-video/'+prod.news_category.slug">{{ prod.news_category.name }}</a>
                        </small>
                    </div>
                    <div class="d-flex pt-2 mb-4 justify-content-center">
                        <video v-if="newsDetail.url" autoplay loop controls>
                            <source :src="host_url + newsDetail.url" type="video/mp4"/>
                            <source :src="host_url + newsDetail.url" type="video/ogg"/>
                            <source :src="host_url + newsDetail.url" type="video/x-m4k"/>
                        </video>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group mb-2 col col-md-4 col-lg-4 col-sm-12 col-xs-12">
                    <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('action.share')) }}: </span>
                    <a :href="'https://www.facebook.com/sharer/sharer.php?u=http://autoviet247.com/short-video/chi-tiet/'+newsDetail.slug" type="button" class="btn btn-success me-2 btn-sm" target="_blank"><i class="fab fa-facebook"></i></a>
                    <a :href="'https://twitter.com/intent/tweet?text='+newsDetail.name+'&url=http://autoviet247.com/short-video/chi-tiet/'+newsDetail.slug+'&hashtags='+newsDetail.keywords" type="button" class="btn btn-info me-2 btn-sm" target="_blank"><i class="fab fa-twitter"></i></a>
                </div>
                <div class="form-group mb-2 col col-md-8 col-log-8 col-sm-12 col-xs-12">
                    <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('action.more')) }}: </span>
                    <a v-if="newsDetail.shop != null" class="btn btn-danger rounded-pill btn-sm me-2" :href="'/shop/chi-tiet/'+newsDetail.shop.slug">{{ newsDetail.shop.name }}</a> 
                    <a v-if="newsDetail.shop != null" class="btn btn-primary rounded-pill btn-sm" :href="'/san-pham/detail/'+newsDetail.product.slug">{{ newsDetail.product.name }}</a>
                </div>
            </div>
            <div class="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                <h4 class="display-7 mb-3">{{ $filters.capitalize($t('title.related-videos')) }}</h4>
            </div>
            <div class="row g-4">
                <div v-for="(item, index) in news" class="col-xl-2 col-lg-2 col-md-6 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <video-item :item="item" />
                </div>
                <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm" href="/short-video">{{ $filters.capitalize($t('action.more')) }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { detail, list, related } from '@/api/short_video';
import NavbarIndex from '@/components/NavbarIndex.vue';
import VideoItem from './components/VideoItem.vue';
import { add } from '@/api/like_video';
import { api_backend } from '@/constants/configs';
export default {
    name: "NewsDetail",
    data(){
        return {
            newsDetail: {
                user: {}
            },
            news: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
            is_login: false,
            like_number: 0,
            class_like: 'text-default',
            search: {
                keywords: '',
                page: 1,
                pagesize: 30,
                sort: [],
                from_date: '',
                to_date: ''
            }
        };
    },
    components: {
        VideoItem,
        NavbarIndex
    },
    async created(){
        let res = await detail(this.$route.params.slug);
        if (res != null && res.status == 200 && res.data != null ){
            this.newsDetail = res.data;
            document.title = this.newsDetail.name;
            const descEl = document.querySelector('head meta[name="description"]');
            descEl.setAttribute('content', this.newsDetail.meta_description);
            const keywordscEl = document.querySelector('head meta[name="keywords"]');
            keywordscEl.setAttribute('content', this.newsDetail.keywords);
            // set facebook feature
            const titleElFacebook = document.querySelector('head meta[property="og:title"]');
            if (titleElFacebook != null) {
                titleElFacebook.setAttribute('content', this.newsDetail.name);
            }
            const descElFacebook = document.querySelector('head meta[property="og:description"]');
            if (descElFacebook != null) {
                descElFacebook.setAttribute('content', this.newsDetail.meta_description);
            }
            const urlElFacebook = document.querySelector('head meta[property="og:url"]');
            if (urlElFacebook != null) {
                urlElFacebook.setAttribute('content', this.host_url+"short-video/chi-tiet/"+this.newsDetail.slug);
            }
            const imageElFacebook = document.querySelector('head meta[property="og:image"]');
            if (imageElFacebook != null) {
                imageElFacebook.setAttribute('content', this.host_url + this.newsDetail.feature_image);
            }
            // set twitter feature
            const titleElTwitter= document.querySelector('head meta[name="twitter:title"]');
            if (titleElTwitter != null) {
                titleElTwitter.setAttribute('content', this.newsDetail.name);
            }
            const descElTwitter = document.querySelector('head meta[name="twitter:description"]');
            if (descElTwitter != null) {
                descElTwitter.setAttribute('content', this.newsDetail.meta_description);
            }
            const imageElTwitter = document.querySelector('head meta[name="twitter:image"]');
            if (imageElTwitter != null) {
                imageElTwitter.setAttribute('content', this.host_url + this.newsDetail.feature_image);
            }
            if (this.newsDetail.like_videos != null) {
                this.like_number = this.newsDetail.like_videos.length;
                if (this.is_login) {
                    let found = this.newsDetail.like_videos.filter(el => el.user_id == this.$store.state.user.id);
                    if (found) {
                        this.class_like = 'text-success';
                    }
                }
                
            }
            let resRelated = await related(this.newsDetail.id);
            if (resRelated != null && resRelated.status == 200 && resRelated.data != null && resRelated.data.data != null && resRelated.data.data.length > 0 ){
                this.news = resRelated.data.data;
            } else {
                let resList = await list('page=1&pagesize=4');
                if (resList != null && resList.status == 200 && resList.data != null ){
                    this.news = resList.data.data;
                }
            }
        }
    },
    methods: {
        async likeVideo() {
            if (!this.is_login) {
                this.$router.push({ path: '/login' });
                return;
            }
            if (this.newsDetail != null && this.newsDetail.id > 0) {
                let res = await add({
                    video_id: this.newsDetail.id
                });
                if (res != null && res.status == 200 && res.data != null) {
                    this.like_number = res.data.length;
                    if (res.data.length > 0) {
                        let found = res.data.filter(el => el.user_id == this.$store.state.user.id);
                        if (found && found.length > 0) {
                            this.class_like = 'text-success';
                        } else {
                            this.class_like = 'text-default';
                        }
                    } else {
                        this.is_like = 'text-default';
                    }
                    this.$flashMessage.show({
                        type: 'success',
                        title: this.$filters.capitalize(this.$t('title.success')),
                        message: this.$filters.capitalize(this.$t('title.save-success')),
                        time: 3000,
                        group: 'cart'
                    });
                } else {
                    this.$flashMessage.show({
                        type: 'error',
                        title: this.$filters.capitalize(this.$t('title.failure')),
                        message: this.$filters.capitalize(this.$t('title.save-failure')),
                        time: 3000,
                        group: 'cart'
                    });
                }
            }
            
        },
        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        onSearch() {
            this.$router.push({path: '/short-video', query: this.search});
        }
    },
    watch: {
    '$store.state.islogin': {
        handler(newValue) {
            this.is_login = newValue;
        },
        immediate: true,
        deep: true
    }
  }
}
</script>
<style>
video {
    min-height: 50vh;
    max-width: 50%;
    width: 50%;
    height: auto;
    padding: 0;
}
.full-screen {
    transition: 150ms;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
    background-color: rgba(255, 255, 255, 0.5);
    border-color: transparent;
    border-radius: 50%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;

}
.full-screen:hover {
    background-color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 768px) {
    video {
        min-height: 100vh;
        max-width: 100%;
        width: 100%;
        height: auto;
        padding: 0;
    }
}
@media (hover: hover) {
    .full-screen {
    opacity: 0;
    }
    main:hover .full-screen {
    opacity: 1;
    }
}

</style>