<template>
<div class="modal fade" :class="show ? 'show showmodal' : 'hidemodal'" tabindex="-1" role="dialog">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Xóa phần tử</h5>
      </div>
      <div class="modal-body">
        <span class="text-danger">Bạn muốn xóa phần tử này?</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" @click="ok">Đồng ý</button>
        <button type="button" class="btn btn-success btn-sm" data-dismiss="modal" @click="cancel">Đóng</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    name: "confirm-delete",
    props: [
        'ok',
        'cancel',
        'show'
    ]
};
</script>
<style>
.showmodal {
    display: block !important;
}
.hidemodal {
    display: none !important;
}
</style>