<template>
    <div class="container-xxl py-3" style="min-height: 140px;">
        <div class="container" style="min-height: 140px;">
            <div class="mshopes-header-section pb-3 border-bottom border-success border-3">
                <div class="title text-center text-success">
                    {{ $filters.capitalize($t('title.services-for-you')) }}
                </div>
            </div>
            <div class="row g-2 mt-3">
                <div v-for="(prod, index) in products" class="col-xl-2 col-lg-2 col-md-3 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="product-item" :href="'/san-pham/detail/'+prod.slug">
                        <div class="position-relative bg-light overflow-hidden">
                            <img v-if="prod.feature_image != null" class="img-fluid w-100" :src=" host_url + prod.feature_image" alt="NoImage">
                            <div class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">{{ $filters.capitalize($t('title.new')) }}</div>
                        </div>
                        <div class="text-center pt-2 text-responsive">
                            <p class="d-block h6 mb-2">{{ prod.name }}</p>
                            <span v-if="prod.selling_price > 0" class="text-primary me-1 text-responsive">{{ prod.selling_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                            <span v-else class="text-danger me-1 text-responsive">{{ $filters.capitalize($t('title.contact')) }}</span>
                            <span v-if="prod.selling_price > 0" class="text-body text-decoration-line-through text-responsive">{{ prod.show_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <a class="btn btn-default btn-sm py-2 px-3 text-success border" href="/san-pham">{{ $filters.capitalize($t('action.more')) }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {list} from '@/api/product'
import { api_backend } from '@/constants/configs';
export default {
    name: "ProductIndexHome",
    data(){
        return {
            products: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        };
    },
    components: {
        
    },
    created() {
        this.getProducts();
    },
    methods: {
        async getProducts(){
            let res = await list('page=1&pagesize=20&is_top=1&category_type_id=1');
            if (res != null && res.status == 200 && res.data != null ){
                this.products = res.data.data;
            }
        }, 
    }
}
</script>
<style>
.mshopes-header-section .title {
    text-transform: uppercase;
}
.mshopes-header-section-content .title {
    text-transform: uppercase;
}
@media screen and (max-width: 768px) {
    
}
</style>