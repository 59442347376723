import request from '@/utils/request'
function list(query){
    return request({
        url: '/shop?'+query,
        method: 'get'
    });
}
function listTop(query){
    return request({
        url: '/home-shop?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/shop/'+id,
        method: 'get'
    });
}
function listOrder(query) {
    return request({
        url: '/manager/order?'+query,
        method: 'get'
    });
}
function editOrder(id, status) {
    return request({
        url: '/manager/order/'+id.toString()+'/'+status,
        method: 'put'
    });
}
function detailOrder(id) {
    return request({
        url: '/manager/order/'+id,
        method: 'get'
    });
}
function deleteOrder(id) {
    return request({
        url: '/manager/order/'+id.toString(),
        method: 'DELETE'
    });
}
function listStatus() {
    return request({
        url: '/manager/order/liststatus',
        method: 'get'
    });
}
function listByCategory(query, categoryId){
    return request({
        url: '/shop-by-category/'+categoryId+'?'+query,
        method: 'get'
    });
}
export {list, detail, listOrder, editOrder, deleteOrder, listStatus, detailOrder, listTop, listByCategory};