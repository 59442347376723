<template>
    <div class="container-xxl py-3" style="min-height: 150px;">
        <div class="container mshopes-section" style="min-height: 150px;">
            <div class="mshopes-header-section pb-3 border-bottom">
                <div class="title d-flex">
                    <div class="w-50" data-wow-delay="0.1s">
                        {{ $filters.capitalize($t('title.short-video')) }}
                    </div>
                    <div class="w-50 align-items-end justify-content-end d-flex" data-wow-delay="0.1s">
                        <a class="btn btn-default btn-sm py-2 px-3" href="/short-video">
                            {{ $filters.capitalize($t('action.more-videos')) }}
                            <i class="fa fa-angle-right text-success ms-1"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row g-2 mt-3">
                <div v-for="(item, index) in videos" v-bind:key="index" class="col-xl-2 col-lg-2 col-md-3 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <video-item :item="item" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {listTop} from '@/api/short_video'
import VideoItem from '../shortvideo/components/VideoItem.vue'
import { api_backend } from '@/constants/configs';
export default {
    name: "VideoIndexHome",
    data(){
        return {
            videos: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
        }
    },
    async created(){
        let res = await listTop('page=1&pagesize=6');
        if (res != null && res.status == 200 && res.data != null) {
            this.videos = res.data.data;
        }
    },
    components: {
        VideoItem
    },
    methods: {
    },
}
</script>
<style>
.shop-row {
    width: 1112px;
}
.shop-wrap {
    width: 120px !important;
    border-bottom: 1px solid rgb(228, 223, 223);
    border-right: 1px solid rgb(228, 223, 223);
}
.shop-wrap:last-child {
    border-right: none;
}
.shop-item {
    display: block;
}
.mshopes-header-section .title {
    text-transform: uppercase;
}
.mshopes-header-section-content .title {
    text-transform: uppercase;
}
.product-category-item .title {
    text-transform: uppercase;
}
.v-hl-svg {
    background: #f0ebeb !important;
    color: #3CB815 !important;
}
.slider-shop {
    max-height: 600px;
    width: 160px;
}
@media screen and (max-width: 768px) {
    
}
</style>