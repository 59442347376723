import { createRouter, createWebHistory } from "vue-router"
// import store from "@/store"
// import { getLogin } from "@/utils/auth"
import HomePage from "../views/HomePage.vue"
import ProductIndex from "../views/product/Index.vue"
import ProductCategoryIndex from "../views/product/IndexCategory.vue"
import ProductDetail from "../views/product/Detail.vue"
import NewsIndex from "../views/news/Index.vue"
import NewsCategoryIndex from "../views/news/IndexCategory.vue"
import NewsDetail from "../views/news/Detail.vue"
import ShortVideoIndex from "../views/shortvideo/Index.vue"
import ShortVideoCategoryIndex from "../views/shortvideo/IndexCategory.vue"
import ShortVideoDetail from "../views/shortvideo/Detail.vue"
import CartIndex from "../views/cart/index.vue"
import ContactUs from "../views/ContactUs.vue"
import AboutPage from "../views/AboutPage.vue"
import Page404 from "../views/404Page.vue"
import FeaturePage from "../views/FeaturePage.vue"
import LoginPage from '../views/LoginPage.vue'
import RegisterPage from '../views/RegisterPage.vue'
import VerifyPage from '../views/VerifyPage.vue'
import BookingCreate from '../views/booking/create.vue'
import BookService from '../views/booking/BookService.vue'
import BookCarSale from '../views/booking/BookCarSale.vue'
import PorfileIndex from "../views/profile/index.vue"
import OrderIndex from "../views/order/Index.vue"
import OrderDetail from "../views/order/Detail.vue" 
import ServiceRequestIndex from "../views/servicerequest/Index.vue"
import ServiceRequestDetail from "../views/servicerequest/Detail.vue" 
import Page403 from '../views/403Page.vue'
import ShopIndex from "../views/shop/Index.vue"
import ShopCategoryIndex from "../views/shop/IndexCategory.vue"
import ShopDetail from "../views/shop/Detail.vue"
import GoogleReturn from "@/views/GoogleReturn.vue"
import { list } from "@/api/permission"
const routes = [
    {
      path: "/",
      name: "Home",
      component: HomePage
    },
    {
        path: "/san-pham/:slug",
        name: "SanPhamCategory",
        component: ProductCategoryIndex
    },
    {
        path: "/san-pham",
        name: "SanPham",
        component: ProductIndex
    },
    {
        path: "/san-pham/detail/:slug",
        name: "DetailSanPham",
        component: ProductDetail
    },
    {
        path: "/shop",
        name: "ShopIndex",
        component: ShopIndex
    },
    {
        path: "/shop/:slug",
        name: "ShopCategoryIndex",
        component: ShopCategoryIndex
    },
    {
        path: "/shop/chi-tiet/:slug",
        name: "ShopDetail",
        component: ShopDetail
    },
    {
        path: "/blog",
        name: "TinTuc",
        component: NewsIndex
    },
    {
        path: "/blog/:slug",
        name: "TinTucCategory",
        component: NewsCategoryIndex
    },
    {
        path: "/blog/chi-tiet/:slug",
        name: "DetailTinTuc",
        component: NewsDetail
    },
    {
        path: "/short-video",
        name: "ShortVideo",
        component: ShortVideoIndex
    },
    {
        path: "/short-video/:slug",
        name: "ShortVideoCategory",
        component: ShortVideoCategoryIndex
    },
    {
        path: "/short-video/chi-tiet/:slug",
        name: "DetailShortVideo",
        component: ShortVideoDetail
    },
    {
        path: "/lien-he",
        name: "LienHe",
        component: ContactUs
    },
    {
        path: "/gioi-thieu",
        name: "GioiThieu",
        component: AboutPage
    },
    {
        path: "/cart",
        name: "CartIndex",
        component: CartIndex
    },
    {
        path: "/booking",
        name: "BookingCreate",
        component: BookingCreate
    },
    {
        path: "/book-service/:id",
        name: "BookService",
        component: BookService
    },
    {
        path: "/book-car-sale/:id",
        name: "BookCarSale",
        component: BookCarSale
    },
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage
    },
    {
        path: "/register",
        name: "RegisterPage",
        component: RegisterPage
    },
    {
        path: "/emaiverify/:id/:hash",
        name: "EmailVerify",
        component: VerifyPage
    },
    {
        path: "/404",
        name: "page404",
        component: Page404,
        meta: {requiresAuth: false}
    },
    {
        path: "/403",
        name: "page403",
        component: Page403,
        meta: {requiresAuth: false}
    },
    {
        path: "/dac-diem",
        name: "page404",
        component: FeaturePage,
        meta: {requiresAuth: false}
    },
    {
        path: "/manager/profile",
        name: "ProfileIndex",
        component: PorfileIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/order",
        name: "OrderIndex",
        component: OrderIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/service-request",
        name: "ServiceRequestIndex",
        component: ServiceRequestIndex,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/order/:id",
        name: "OrderDetail",
        component: OrderDetail,
        meta: {requiresAuth: true}
    },
    {
        path: "/manager/service-request/:id",
        name: "ServiceRequestDetail",
        component: ServiceRequestDetail,
        meta: {requiresAuth: true}
    },
    {
        path: "/google/return",
        name: "GoogleReturn",
        component: GoogleReturn,
        meta: {requiresAuth: false}
    },
  ];
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
  });
  router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta != null &&  record.meta.requiresAuth)) {
        let res = await list();
        if (res != null && res.status == 200 && res.data != null && res.data.includes(to.meta.permission)) {
            next();
            return;
        } else if (to.meta.permission == null) {
            next();
            return;
        }
        next('403');
    } else {
      next();
    }
  });
  export default router;