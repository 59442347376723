import request from '@/utils/request'
function list(query){
    return request({
        url: '/like-video?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/like-video/'+id,
        method: 'get'
    });
}
function add(data){
    return request({
        url: '/like-video',
        method: 'post',
        data: data
    });
}
function edit(data){
    return request({
        url: '/like-video',
        method: 'put',
        data: data
    });
}
export {list, detail, add, edit};