import { useCookies } from "vue3-cookies";
import { TokenKey, CartKey, LoginKey, UserKey } from "@/constants/cookies";
const { cookies } = useCookies();
export function getCsrfToken() {
  return cookies.get(TokenKey);
}

export function setCsrfToken(token) {
    cookies.set(TokenKey, token);
}

export function removeCsrfToken() {
  return cookies.remove(TokenKey);
}

export function getCart() {
  let cart = cookies.get(CartKey);
  if (cart !== null && typeof cart !== "undefined") {
    return JSON.parse(cart);
  }
  return [];
}

export function setCart(cart) {
  cookies.remove(CartKey);
  cookies.set(CartKey, JSON.stringify(cart));
}

export function removeCart() {
  return cookies.remove(CartKey);
}

export function getLogin() {
  let login = cookies.get(LoginKey);
  if (login && login.toLocaleLowerCase() == "true") {
    return true;
  }
  return false;
}

export function setLogin(login) {
  cookies.set(LoginKey, login.toString());
}

export function removeLogin() {
  return cookies.remove(LoginKey);
}

export function getUser() {
  let data = cookies.get(UserKey);
  // console.log(data);
  if (data !== null && typeof data !== "undefined") {
    // return JSON.parse(data);
    return data;
  }
  return {};
}

export function setUser(data) {
  cookies.remove(UserKey);
  cookies.set(UserKey, JSON.stringify(data));
}

export function removeUser() {
  return cookies.remove(UserKey);
}