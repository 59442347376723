<template>
    <!-- Page Header Start -->
    <div class="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-3 mb-3 animated slideInDown">{{ $filters.capitalize($t('title.google-return')) }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="#">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.google-return')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- 404 Start -->
    <div class="container-xxl py-6 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <i class="bi bi-exclamation-triangle display-1" :class="(login) ? 'text-primary': 'text-danger'"></i>
                    <h1 class="mb-4">{{ (login) ? $filters.capitalize($t('title.google-success')) : $filters.capitalize($t('title.google-error')) }}</h1>
                    <p class="mb-4">{{ (login) ? $filters.capitalize($t('title.you-login-with-google-successfully')) : $filters.capitalize($t('title.you-login-with-google-failure')) }}</p>
                    <a v-if="!login" class="btn btn-primary rounded-pill py-3 px-5" href="/">{{ $filters.capitalize($t('action.back')) }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- 404 End -->
</template>
<script>
import { profile } from '@/api/user';
export default {
    name: "GoogleReturn",
    data(){
        return {
            login: false,
            csrf_token: '',
        };
    },
    async created(){
        const queryParams = this.$route.query;
        if (queryParams && 'csrf_token' in queryParams) {
            let csrf_token = queryParams.csrf_token;
            const csrfEl = document.querySelector('head meta[name="csrf-token"]');
            csrfEl.setAttribute('content', csrf_token);
            this.$store.dispatch('csrf/setCsrfServer', csrf_token);
            let res = await profile();
            if (res != null && res.status == 200 && res.data != null) {
                this.login = true;
                this.csrf_token = csrf_token;
                this.$store.dispatch('setUserServer', {user: res.data, login: true});
                this.$flashMessage.show({
                    type: 'success',
                    title: this.$filters.capitalize(this.$t('title.success')),
                    message: this.$filters.capitalize(this.$t('title.login-success')),
                    time: 3000,
                    group: 'login'
                });
                this.$router.push({ path: '/' });
            } else {
                this.$flashMessage.show({
                    type: 'error',
                    title: this.$filters.capitalize(this.$t('title.failure')),
                    message: this.$filters.capitalize(this.$t('title.login-failure')),
                    time: 3000,
                    group: 'login'
                });
            }
        }
        // this.$store.dispatch('csrf/setCsrfServer', res.data.csrf_token);
    },
}
</script>