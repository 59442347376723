<template>
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <!-- Page Header Start -->
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item">
                        <a class="text-body" href="/shop">
                        {{ $filters.capitalize($t('title.shop')) }}
                        </a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ category.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- Blog Start -->
    <div class="container-xxl py-3">
        <div class="container">
            <div v-if="category != null" class="row g-5 align-items-center">
                <div class="col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <div class="about-img position-relative overflow-hidden p-5 pe-0">
                        <img v-if="category.feature_image != null && category.feature_image != null" class="img-fluid w-100" :src="host_url + category.feature_image">
                    </div>
                </div>
                <div class="col-lg-9 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-4">{{ category.name }}</h1>
                    <div class="row">
                        <div class="form-group mb-2 col col-md-12">
                            <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('action.share')) }}: </span>
                            <a :href="'https://www.facebook.com/sharer/sharer.php?u=http://mshopes.com/shop/'+category.slug" type="button" class="btn btn-success me-2 btn-sm" target="_blank"><i class="fab fa-facebook"></i></a>
                            <a :href="'https://twitter.com/intent/tweet?text='+category.name+'&url=http://mshopes.com/shop/'+category.slug+'&hashtags='+category.keywords" type="button" class="btn btn-info me-2 btn-sm" target="_blank"><i class="fab fa-twitter"></i></a>
                        </div>
                    </div>
                    <p class="mb-4" v-html="category.description"></p>
                </div>
            </div>
            <sort-form :change-sort="changeSort" />
            <div class="row g-2">
                <div class="col-2 col-sidebarmenu">
                    <filter-form :is-category="true" :change-location="changeLocation" :change-ship-method="changeShipMethod" />
                </div>
                <div class="col-10 col-result">
                    <div class="row g-2">
                        <div v-for="(prod, index) in news" class="col-xl-2 col-lg-2 col-md-6 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                            <shop-item :prod="prod" />
                        </div>
                        <pagination-index :page="search.page" :total="search.total" :page-size="search.pagesize" :change-page="changePage" :next-page="nextPage" :prev-page="prevPage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Blog End -->
</template>
<script>
import { listByCategory } from "@/api/shop";
import PaginationIndex from '../components/Pagination.vue'
import FilterForm from "./components/FilterForm.vue";
import SortForm from "./components/SortForm.vue";
import NavbarIndex from "@/components/NavbarIndex.vue";
import ShopItem from './components/ShopItem.vue';
import {detail} from '@/api/shop_category'
import { api_backend } from '@/constants/configs';
export default {
    name: "NewsCategoryIndex",
    data() {
        return {
            news: [],
            category: {},
            search: {
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: [],
                from_date: '',
                to_date: '',
                province_code: null
            },
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        };
    },
    components: {
        PaginationIndex,
        NavbarIndex,
        SortForm,
        FilterForm,
        ShopItem
    },
    async created(){
        await this.getDetail();
        // await this.getList();
    },
    methods: {
        async getDetail(){
            let res = await detail(this.$route.params.slug);
            if (res != null && res.status == 200 && res.data != null ){
                this.category = res.data;
                document.title = this.category.name;
                const descEl = document.querySelector('head meta[name="description"]');
                descEl.setAttribute('content', this.category.meta_description);
                const keywordscEl = document.querySelector('head meta[name="keywords"]');
                keywordscEl.setAttribute('content', this.category.keywords);
                // set facebook feature
                const titleElFacebook = document.querySelector('head meta[property="og:title"]');
                if (titleElFacebook != null) {
                    titleElFacebook.setAttribute('content', this.category.name);
                }
                const descElFacebook = document.querySelector('head meta[property="og:description"]');
                if (descElFacebook != null) {
                    descElFacebook.setAttribute('content', this.category.meta_description);
                }
                const urlElFacebook = document.querySelector('head meta[property="og:url"]');
                if (urlElFacebook != null) {
                    urlElFacebook.setAttribute('content', this.host_url+"shop/"+this.category.slug);
                }
                const imageElFacebook = document.querySelector('head meta[property="og:image"]');
                if (imageElFacebook != null) {
                    imageElFacebook.setAttribute('content', this.host_url + this.category.feature_image);
                }
                // set twitter feature
                const titleElTwitter= document.querySelector('head meta[name="twitter:title"]');
                if (titleElTwitter != null) {
                    titleElTwitter.setAttribute('content', this.category.name);
                }
                const descElTwitter = document.querySelector('head meta[name="twitter:description"]');
                if (descElTwitter != null) {
                    descElTwitter.setAttribute('content', this.category.meta_description);
                }
                const imageElTwitter = document.querySelector('head meta[name="twitter:image"]');
                if (imageElTwitter != null) {
                    imageElTwitter.setAttribute('content', this.host_url + this.category.feature_image);
                }
                this.getList(this.category.id);
            }
        },
        async getList(categoryId){
            let query = 'page='+this.search.page+'&pagesize='+this.search.pagesize;
            if (this.search.keywords.trim() != '') {
                query += '&keywords='+this.search.keywords.trim()
            }
            if (this.search.from_date.trim() != '') {
                query += '&from_date='+this.search.from_date.trim()
            }
            if (this.search.to_date.trim() != '') {
                query += '&to_date='+this.search.to_date.trim()
            }
            if (this.search.province_code != null) {
                query += '&province_code='+this.search.province_code
            }
            if (this.search.sort.length > 0) {
                for(let i = 0; i < this.search.sort.length; i++) {
                    let arr = this.search.sort[i].split('-');
                    if (arr.length >= 2) {
                        query += '&sort['+arr[0]+']='+arr[1];
                    }
                }
            }
            let res = await listByCategory(query, categoryId);
            if (res != null && res.status == 200 && res.data != null ){
                this.search.page = res.data.page;
                this.search.pagesize = res.data.pagesize;
                this.search.total = res.data.total;
                this.news = res.data.data;
            }
        },
        changePage(value){
        if (this.search.page != value) {
            this.search.page = value;
            this.$router.push({ path: '/shop/'+this.$route.params.slug, query: this.search });
        }
        },
        nextPage(){
            this.search.page = Number(this.search.page)+1;
            this.$router.push({ path: '/shop/'+this.$route.params.slug, query: this.search });
        },

        prevPage(){
            this.search.page = Number(this.search.page)-1;
            this.$router.push({ path: '/shop/'+this.$route.params.slug, query: this.search });
        },

        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        async onSearch(){
            this.$router.push({ path: '/shop/'+this.$route.params.slug, query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort, from_date: this.search.from_date, to_date: this.search.to_date, province_code: this.search.province_code } });
        },
        onRefresh(){
            this.search = {
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: [],
                from_date: '',
                to_date: '',
                province_code: null
            };
            this.$router.push({ path: '/shop/'+this.$route.params.slug, query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort, 
            from_date: this.search.from_date, to_date: this.search.to_date, province_code: this.search.province_code } });
        },
        changeSort(e){
            this.search.sort = [];
            this.search.sort.push(e.target.value);
            this.$router.push({ path: '/shop/'+this.$route.params.slug, query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort, from_date: this.search.from_date, to_date: this.search.to_date, province_code: this.search.province_code } });
        },
        changeFromDate(e) {
            this.search.from_date = e.target.value;
        },
        changeToDate(e) {
            this.search.to_date = e.target.value;
        },
        changeLocation(e){
            this.search.province_code = e.target.value;
        },
    },
    watch: {
        '$route'(to, from){
            console.log(to);
            console.log(from);
            // console.log(from);
            // var params = to.fullPath.split('?');
            // var query = '';
            // if (params.length > 1) {
            //     query = params.slice(-1);
            // }
            this.getList(this.category.id);
        }
    },
}
</script>