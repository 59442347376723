<template>
        <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
<!-- Page Header Start -->
<div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 mb-3 animated slideInDown">{{ $filters.capitalize($t('action.booking')) }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('action.booking')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- Contact Start -->
    <div class="container-xxl py-6">
        <div class="container">
            <div class="row g-5 justify-content-center">
                <div class="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                    <h5 class="mb-4 display-7 justify-content-center">{{ $filters.capitalize($t('title.order-information')) }}</h5>
                    <form>
                        <div class="row g-4">
                            <div v-for="(prod, index) in carts" class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                                <div class="product-item">
                                    <div class="position-relative bg-light overflow-hidden">
                                        <img v-if="prod.feature_image != null" class="img-fluid w-100" :src="prod.feature_image" alt="NoImage">
                                        <div class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">{{ $filters.capitalize($t('title.new')) }}</div>
                                    </div>
                                    <div class="text-center p-4">
                                        <a class="d-block h5 mb-2" :href="'/san-pham/detail/'+prod.slug">{{ prod.name }}</a>
                                        <span class="text-primary me-1">{{ $filters.capitalize($t('title.quantity')) }}: {{ prod.qty }}</span>
                                        <br/>
                                        <span class="text-body">{{ prod.selling_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }} </span>
                                    </div>
                                    <div class="d-flex border-top">
                                        <small class="w-50 text-center border-end py-2">
                                            <a class="text-body" :href="'/san-pham/detail/'+prod.slug"><i class="fa fa-eye text-primary me-2"></i>{{ $filters.capitalize($t('action.detail')) }}</a>
                                        </small>
                                        <small class="w-50 text-center py-2">
                                            <a class="text-body" href="javascript:;" @click="removeFromCart(prod)"><i class="fa fa-trash text-danger me-2"></i>{{ $filters.capitalize($t('action.delete')) }}</a>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-left wow fadeInUp" data-wow-delay="0.1s">
                                <p><i class="fa fa-soild fa-dollar-sign text-secondary me-3"></i>{{ $filters.capitalize($t('order.total-money')) }}: <span class="text-secondary text-bold">{{ total.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span></p>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <div class="col-12 text-left wow fadeInUp" data-wow-delay="0.1s">
                                <p><i class="fa fa-soild fa-dollar-sign text-secondary me-3"></i>{{ $filters.capitalize($t('order.ship-money')) }}: <span class="text-secondary text-bold">{{ ship_price.total.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span></p>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="street_address" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.street-address')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="street_address" type="text" class="form-control form-control-sm" id="street_address" placeholder="" @click="clearError" @change="changeStreetAddress">
                                <span class="error text-danger">{{ errorAddress }}</span>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="to_province_id" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.ship-province')) }}</label>
                            <div class="col-sm-10">
                                <select class="form-control form-control-sm" placeholder="Chon province" v-model="to_province_id" @change="changeProvince">
                                    <option v-for="(item) in provinces" v-bind:key="item.ProvinceID" :value="item.ProvinceID">{{ item.ProvinceName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="to_district_id" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.ship-district')) }}</label>
                            <div class="col-sm-10">
                                <select class="form-control form-control-sm" placeholder="Chon district" v-model="to_district_id" @change="changeDistrict">
                                    <option v-for="(item) in districts" v-bind:key="item.DistrictID" :value="item.DistrictID">{{ item.DistrictName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="to_ward_code" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.ship-ward')) }}</label>
                            <div class="col-sm-10">
                                <select class="form-control form-control-sm" placeholder="Chon Ward" v-model="to_ward_code" @change="changeWard">
                                    <option v-for="(item) in wards" v-bind:key="item.WardCode" :value="item.WardCode">{{ item.WardName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="service_id" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.ship-service')) }}</label>
                            <div class="col-sm-10">
                                <select class="form-control form-control-sm" placeholder="Chon Service" v-model="service_id" @change="changeService">
                                    <option v-for="(item) in services" v-bind:key="item.ID" :value="item.service_id">{{ item.short_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="ship_address" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.receive-address')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="ship_address" type="text" class="form-control form-control-sm" id="ship_address" placeholder="" readonly>
                                <span class="error text-danger">{{ errorAddress }}</span>
                            </div>
                        </div>
                        <div v-if="!is_login" class="form-group row g-3 mt-1">
                            <label for="email" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.email')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="email" type="email" class="form-control form-control-sm" id="email" placeholder="Your Email" @click="clearError">
                                <span class="error text-danger">{{ errorEmail }}</span>
                            </div>
                        </div>
                        <div v-if="!is_login" class="form-group row g-3 mt-1">
                            <label for="fullname" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.name')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="fullname" type="text" class="form-control form-control-sm" id="fullname" placeholder="" @click="clearError">
                                <span class="error text-danger">{{ errorFullname }}</span>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="receive_phone" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.receive-phone')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="receive_phone" type="text" class="form-control form-control-sm" id="receive_phone" placeholder="" @click="clearError">
                                <span class="error text-danger">{{ errorPhone }}</span>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="notes" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.notes')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="notes" type="text" class="form-control form-control-sm" id="notes" placeholder="" @click="clearError">
                                <span class="error text-danger">{{ errorNotes }}</span>
                            </div>
                        </div>
                        <div class="row g-3 mt-1">
                            <div class="col-6 container d-flex align-items-center justify-content-center">
                                <button class="btn btn-primary rounded-pill py-2 px-3 btn-sm" type="button" @click="submitOrder">{{ $filters.capitalize($t('action.booking')) }}</button>
                            </div>
                            <div class="col-6 container d-flex align-items-center justify-content-center">
                                <a v-if="!is_login" class="btn btn-success rounded-pill py-2 px-3 btn-sm" type="button" href="/login">{{ $filters.capitalize($t('title.login')) }}</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
</template>
<script>
import {add} from '@/api/order'
import { provinces as shipProvinces, districts as shipDistricts, wards as shipWards, services as shipServices, price as shipPrice } from '@/api/ship';
import NavbarIndex from '@/components/NavbarIndex.vue';
export default {
    name: "BookingCreate",
    data(){
        return {
            carts: [],
            total: 0,
            is_login: false,
            email: '',
            ship_address: '',
            receive_phone: '',
            fullname: '',
            errorAddress: '',
            errorEmail: '',
            errorFullname: '',
            errorPhone: '',
            haveError: false,
            notes: '',
            errorNotes: '',
            to_province_id: '',
            to_district_id: '',
            to_ward_code: '',
            service_id: '',
            street_address: "",
            search: {
                keywords: '',
                page: 1,
                pagesize: 30,
                total: 0
            },
            provinces: [],
            districts: [],
            wards: [],
            services: [],
            ship_price: {
                total: 0
            }
        };
    },
    components: {
        NavbarIndex
    },
    created(){
        this.getShipProvinces();
    },
    methods: {
        async getShipProvinces(){
            let res = await shipProvinces();
            if (res != null && res.status == 200 && res.data != null) {
                this.provinces = res.data;
            }
        },
        async getShipDistricts(provinceId){
            let res = await shipDistricts(provinceId);
            if (res != null && res.status == 200 && res.data != null) {
                this.districts = res.data;
            }
        },
        async getShipWards(districtId){
            let res = await shipWards(districtId);
            if (res != null && res.status == 200 && res.data != null) {
                this.wards = res.data;
            }
        },
        async getServices(){
            if (this.carts.length <= 0) {
                return;
            }
            let data = new FormData();
            data.append('to_district', this.to_district_id);
            for(let i = 0; i < this.carts.length; i++){
                data.append('product_ids[]', this.carts[i].id);
            }
            let res = await shipServices(data);
            if (res != null && res.status == 200 && res.data != null) {
                this.services = res.data;
            }
        },
        async getPriceShip(value) {
            if (this.carts.length <= 0) {
                return;
            }
            if (this.to_district_id <= 0 || this.to_ward_code == null || this.to_ward_code == '') {
                return;
            }
            let data = new FormData();
            data.append('to_district_id', Number(this.to_district_id));
            data.append('to_ward_code', this.to_ward_code.toString());
            data.append('service_id', Number(value));
            for(let i = 0; i < this.carts.length; i++) {
                data.append('products['+i+'][id]', this.carts[i].id);
                data.append('products['+i+'][qty]', this.carts[i].qty);
            }
            let res = await shipPrice(data);
            if (res != null && res.status == 200 && res.data != null) {
                this.ship_price = res.data;
            }
        },
        removeFromCart(prod){
            this.$store.dispatch('addProductToCart', {id: prod.id, name: prod.name, qty: -prod.qty, selling_price: prod.selling_price, slug: prod.slug});
        },
        async submitOrder(){
            if (!this.is_login) {
                if (this.email.trim() == '') {
                    this.haveError = true;
                    this.errorEmail = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.email'));
                    return;
                }
                if (!this.isValidEmail()) {
                    this.haveError = true;
                    this.errorEmail = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.email'));
                    return;
                }
                if (this.fullname.trim() == '') {
                    this.haveError = true;
                    this.errorFullname = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.name'));
                    return;
                }
                
            }
            if (this.receive_phone.trim() == '') {
                    this.haveError = true;
                    this.errorPhone = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('order.receive-phone'));
                    return;
                }
            if (this.ship_address.trim() == '') {
                    this.haveError = true;
                    this.errorAddress = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('order.receive-address'));
                    return;
                }
            if (this.notes.trim() == '') {
                    this.haveError = true;
                    this.errorNotes = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('order.notes'));
                    return;
                }
            if (this.to_province_id <= 0) {
                this.haveError = true;
                this.errorNotes = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('order.ship-province'));
                return;
            }
            if (this.to_district_id <= 0) {
                this.haveError = true;
                this.errorNotes = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('order.ship-district'));
                return;
            }
            if (this.to_ward_code == '') {
                this.haveError = true;
                this.errorNotes = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('order.ship-ward'));
                return;
            }
            if (this.service_id <= 0) {
                this.haveError = true;
                this.errorNotes = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('order.ship-service'));
                return;
            }
            let data = new FormData();
            if (!this.is_login) {
                data.append('email', this.email);
                data.append('fullname', this.fullname);    
            }
            data.append('receive_phone', this.receive_phone);
            data.append('ship_address', this.ship_address);
            data.append('to_province_id', this.to_province_id);
            data.append('to_district_id', this.to_district_id);
            data.append('to_ward_code', this.to_ward_code);
            data.append('service_id', this.service_id);
            // let products = [];
            for(var i = 0; i < this.carts.length; i++) {
                data.append('products['+i+'][id]', this.carts[i].id);
                data.append('products['+i+'][qty]', this.carts[i].qty);
            }
            if (this.ship_price != null) {
                if (this.ship_price.total != null) {
                    data.append('ship_total', this.ship_price.total);
                }
                if (this.ship_price.service_fee != null) {
                    data.append('service_fee', this.ship_price.service_fee);
                }
                if (this.ship_price.insurance_fee != null) {
                    data.append('insurance_fee', this.ship_price.insurance_fee);
                }
                if (this.ship_price.pick_station_fee != null) {
                    data.append('pick_station_fee', this.ship_price.pick_station_fee);
                }
                if (this.ship_price.coupon_value != null) {
                    data.append('coupon_value', this.ship_price.coupon_value);
                }
                if (this.ship_price.r2s_fee != null) {
                    data.append('r2s_fee', this.ship_price.r2s_fee);
                }
            }
            //data.append('products[]', JSON.stringify({id: this.carts[i].id, qty: this.carts[i].qty}));
            data.append('notes', this.notes);
            data.append('ship_method', 1);
            this.$store.state.isLoading = true;
            let res = await add(data);
            this.$store.state.isLoading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.$store.dispatch('clearCart');
                this.$flashMessage.show({
                    type: 'success',
                    title: this.$filters.capitalize(this.$t('title.success')),
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    time: 3000,
                    group: 'order'
                });
                this.$router.push({ path: '/' });
            } else {
                this.$flashMessage.show({
                    type: 'error',
                    title: this.$filters.capitalize(this.$t('title.failure')),
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    time: 3000,
                    group: 'order'
                });
            }
        },
        clearError(){
            this.errorEmail = '';
            this.errorPassword = '';
            this.haveError = false;
        },
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
        },
        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        onSearch() {
            this.$router.push({path: '/san-pham', query: this.search});
        },
        changeProvince(e){
            this.getShipDistricts(e.target.value);
            this.wards = [];
        },
        changeDistrict(e){
            this.getShipWards(e.target.value);
        },
        changeWard(e){
            if (e.target.value != null) {
                let str = "";
                str = this.street_address.trim();
                let wardFound = this.wards.filter((el) => el.WardCode == this.to_ward_code);
                if (wardFound) {
                    str += ", " + wardFound[0].WardName.trim();
                }
                let districtFound = this.districts.filter((el) => el.DistrictID == this.to_district_id);
                if (districtFound) {
                    str += ", " + districtFound[0].DistrictName.trim();
                }
                let provinceFound = this.provinces.filter((el) => el.ProvinceID == this.to_province_id);
                if (provinceFound) {
                    str += ", " +  provinceFound[0].ProvinceName.trim();
                }
                this.ship_address = str;
            }
            this.getServices(e.target.value);
        },
        changeService(e){
            this.getPriceShip(e.target.value);
        },
        changeStreetAddress(e){
            this.ship_address = e.target.value.trim();
        }
    },
    watch: {
    '$store.state.cart': {
        handler(newCart){
            if (Array.isArray(newCart)) {
                this.total = 0;
                for(var i = 0; i < newCart.length; i++){
                    this.total += Number(newCart[i].selling_price) * Number(newCart[i].qty);
                }
            }
            this.carts = newCart;
        },
        immediate: true,
        deep: true
    },
    '$store.state.islogin': {
        handler(newValue) {
            this.is_login = newValue;
        },
        immediate: true,
        deep: true
    }
  }
};
</script>
<style>
</style>