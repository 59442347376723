<template>
<nav class="navbar navbar-expand-lg navbar-light mshopes-navbar">
  <div class="container">
    <a href="/" class="navbar-brand ms-4 ms-lg-0 ">
        <h1 class="fw-bold text-primary m-0"><img src="/logo.png" style="height: 48px;"/></h1>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <form class="d-flex form-inline">
            <div class="input-group">
                <input class="form-control me-2" @change="changeKeywords" type="text" :placeholder="$filters.capitalize($t('action.search'))" aria-label="Search">
                <button class="btn btn-outline-success" type="button" @click="clickSearch"><i class="fa fa-search me-2"></i> {{ $filters.capitalize($t('action.search')) }}</button>
            </div>
        </form>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
            <a class="nav-link nav-icon iconClass" href="/cart" :title="$filters.capitalize($t('title.shopping-cart'))">
                <i class="fa fa-shopping-cart text-danger" /><span class="badge badge-pill badge-warning">{{car_number}}</span>
            </a>
        </li>
        <a class="nav-item nav-link nav-item-language">
            <select class="language-select form-control" placeholder="Language" v-model="locale">
                <option
                v-for="optionLocale in supportLocales"
                :key="`locale-${optionLocale}`"
                :value="optionLocale">
                {{ optionLocale == 'en' ? '🇬🇧&emsp;' : '🇻🇳&emsp;'}}{{ $filters.capitalize($t('title.'+optionLocale)) }}
                </option>
            </select>
        </a>
      </ul>
    </div>
  </div>
</nav>
<nav class="navbar navbar-expand navbar-light navbar-bottom">
    <div class="container">
        <div class="brand-hidden">
        </div>
        <ul class="navbar-nav me-auto">
            <li class="nav-item">
                <a class="nav-link" href="/san-pham" :title="$filters.capitalize($t('title.product'))">
                    {{ $filters.capitalize($t('title.product')) }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/shop" :title="$filters.capitalize($t('title.shop'))">
                    {{ $filters.capitalize($t('title.shop')) }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/blog" :title="$filters.capitalize($t('title.blog'))">
                    {{ $filters.capitalize($t('title.blog')) }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/short-video" :title="$filters.capitalize($t('title.short-video'))">
                    {{ $filters.capitalize($t('title.short-video')) }}
                </a>
            </li>
        </ul>
    </div>
</nav>
</template>
<script setup>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import {SUPPORT_LOCALES as supportLocales, setI18nLanguage } from '../i18n';
const { locale } = useI18n({ useScope: 'global' });
// let lang = locale.value;
watch(locale, (val) => {
  setI18nLanguage(val);
  // lang = locale.value;
});
</script>
<script>
import { checklogin, logout } from '@/api/appconfig';
import { list } from '@/api/permission';

// import { mapState } from "vuex";
export default {
    name: "NavbarIndex",
    props: [
        'listKeywords',
        'changeKeywords',
        'clickSearch',
    ],
    data(){
        return {
            car_number: 0,
            is_login: false,
            permissions: []
        };
    },
    async created(){
        await this.getPermissions();
        let res = await checklogin();
        if (res != null && res.status == 200 && res.data != null) {
            if (res.data.data != null && res.data.message != 'Unauthorized') {
                this.$store.dispatch('setLoginServer', true);
            } else {
                this.$store.dispatch('setLoginServer', false);
            }
        } else {
            this.$store.dispatch('setLoginServer', false);
        }
    },
    async onMounted(){
        let res = await checklogin();
        if (res != null && res.status == 200 && res.data != null) {
            if (res.data.data != null && res.data.message != 'Unauthorized') {
                this.$store.dispatch('setLoginServer', true);
            } else {
                this.$store.dispatch('setLoginServer', false);
            }
        } else {
            this.$store.dispatch('setLoginServer', false);
        }
    },
    methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    async onlogout(){
        let res = await logout();
        if (res != null && res.status == 200) {
            this.$store.dispatch('setUserServer', {user: {}, login: false});
            this.$store.dispatch('permission/clearPermissions');
            this.$router.push({ path: '/' });
        }
    },
    async getPermissions(){
        let res = await list();
        if (res != null && res.status == 200 && res.data != null) {
            this.permissions = [];
        }
    }
  },
  watch: {
    '$store.state.cart': {
        handler(newCart){
            if (Array.isArray(newCart)) {
                this.car_number = 0;
                for(var i = 0; i < newCart.length; i++){
                    this.car_number += newCart[i].qty;
                }
            }
        },
        immediate: true,
        deep: true
    },
    '$store.state.islogin': {
        handler(newValue) {
            this.is_login = newValue;
            this.getPermissions();
        },
        immediate: true,
        deep: true
    }
  }
};
</script>
<style>
.nav-item-language {
    padding: 25px 15px !important;
    font-weight: 500;
    outline: none;
}
.mshopes-navbar {
    margin-top: 50px;
    background-color: rgb(245, 245, 250);
}
.mshopes-navbar-bottom {
    margin-top: 0px;
    background-color: rgb(245, 245, 250);
}
.mshopes-navbar .navbar-brand {
    width: 20%;
}
.mshopes-navbar #navbarSupportedContent {
    width: 80%;
}
.mshopes-navbar #navbarSupportedContent .form-inline {
    width: 60%;
}
.mshopes-navbar  #navbarSupportedContent .navbar-nav {
    width: 40%;
}
.mshopes-navbar-bottom .navbar-brand {
    width: 20%;
}
.mshopes-navbar-bottom #navbarSupportedContent {
    width: 80%;
}
.mshopes-navbar-bottom #navbarSupportedContent .form-inline {
    width: 60%;
}
.mshopes-navbar-bottom  #navbarSupportedContent .navbar-nav {
    width: 40%;
}
@media screen and (max-width: 768px) {
    .mshopes-navbar .navbar-brand {
        width: auto;
    }
    .mshopes-navbar #navbarSupportedContent {
        width: 100%;
    }
    .mshopes-navbar #navbarSupportedContent .form-inline {
        margin-top: 10px;
        width: 100%;
    }
    .mshopes-navbar  #navbarSupportedContent .navbar-nav {
        width: 100%;
    }
    .mshopes-navbar #navbarSupportedContent .navbar-nav .nav-item {
        padding-left: 20px;
    }
}
.iconClass{
  position: relative;
}
.iconClass i {
    font-size: 32px;
}
.iconClass span{
  position: absolute;
  top: 10px;
  right: -5px;
  color: green;
  font-size: 20px;
}
.language-select {
    background-color: #fff !important;
}
.navbar-bottom {
    width: 100%;
    margin-top: 0px;
}
.navbar-bottom .nav-item .nav-link {
    padding: 0px 12px;
}
.navbar-bottom .brand-hidden {
    display: block;
    width: 20%;
}
.navbar-bottom .navbar-nav {
    width: 80%;
}
@media screen and (max-width: 768px) {
    .navbar-bottom .brand-hidden {
        display: none;
    }
    .navbar-bottom .navbar-nav {
        width: 100%;
    }
}
</style>
