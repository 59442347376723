<template>
    <a class="product-item" :href="'/shop/chi-tiet/'+prod.slug">
        <div class="position-relative bg-light overflow-hidden">
            <img v-if="prod.feature_image != null" class="img-fluid w-100" :src=" host_url + prod.feature_image" alt="NoImage">
        </div>
        <div class="text-body p-1">
            <a class="d-block h6 mb-2 lh-base text-body text-responsive" :href="'/shop/chi-tiet/'+prod.slug">{{ prod.name }}</a>
        </div>
    </a>
</template>
<script>
import { api_backend } from '@/constants/configs';
export default {
    name: "ShopItem",
    data(){
        return {
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
        };
    },
    props: [
        'prod'
    ]
}
</script>
<style>
</style>