import axios from 'axios'
// import { ArgonAlert } from '@/components/ArgonAlert.vue';
import store from '@/store'
import { getCsrfToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: false, // send cookies when cross-domain requests
  // crossOrigin: true,
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.csrf_token) {
      config.headers['X-CSRF-TOKEN'] = `${getCsrfToken()}`
    }
    config.headers['Accept'] = 'application/json'
    // config.headers['Content-Type'] = 'multipart/form-data'
    config.headers['Content-type'] = 'multipart/form-data'
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.request.responseType === 'blob') {
      return response
    }
    // console.log(response)
    //const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (response.status !== 200 && response.status !== 400) {
    //   Message({
    //     message: response.message.toString() || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (response.status === 401 || response.status === 402 || response.status === 403) {
        // to re-login
        // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        //   confirmButtonText: 'Re-Login',
        //   cancelButtonText: 'Cancel',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
        store.dispatch('csrf/resetToken').then(() => {
            location.reload()
        });
      }
      console.log(response);
      return Promise.resolve(response);
    } else {
        // console.log(response);
      return response;
    }
  },
  error => {
    // ArgonAlert({
    //   message: error.response.message || error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    console.log(error);
    return Promise.resolve(error)
  }
)

export default service