<template>
    <!-- Navbar Start -->
    <div class="container-fluid fixed-top px-0 py-0 wow fadeIn" id="navbar-top" data-wow-delay="0.1s" style="margin-top: 0px; max-height: 40px !important;">
            <nav class="navbar navbar-expand navbar-light navbar-top" style="margin-top: 0px; max-height: 40px !important;">
                <div class="container align-items-end" style="margin-top: 0px; max-height: 40px !important;">
                    <ul class="navbar-nav ms-auto mb-lg-0" style="margin-top: 0px; max-height: 40px !important;">
                        <li class="nav-item">
                            <a class="nav-link" href="/gioi-thieu" :title="$filters.capitalize($t('title.about-us'))" style="padding-top: 10px;">
                                {{ $filters.capitalize($t('title.about-us')) }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/lien-he" :title="$filters.capitalize($t('title.contact-us'))" style="padding-top: 10px;">
                                {{ $filters.capitalize($t('title.contact-us')) }}
                            </a>
                        </li>
                        <li v-if="is_login" class="nav-item" style="padding-top: 10px;">
                            <div class="dropdown">
                                <a v-if="is_login" href="/manager" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" :class="getRoute().includes('manager') ? 'active' : ''">{{ $filters.capitalize($t('title.manager')) }}</a>
                                <div class="dropdown-menu m-0">
                                    <a v-if="is_login" href="/manager/order" class="dropdown-item">{{ $filters.capitalize($t('title.order')) }}</a>
                                    <a v-if="is_login" href="/manager/service-request" class="dropdown-item">{{ $filters.capitalize($t('title.service-request')) }}</a>
                                    <a v-if="is_login" href="/manager/profile" class="dropdown-item">{{ $filters.capitalize($t('title.profile')) }}</a>
                                </div>
                            </div>
                        </li>
                        <li v-if = "!is_login"  class="nav-item" style="padding-top: 6px;">
                            <a class="btn-sm-square bg-white rounded-circle" href="/login" :title="$filters.capitalize($t('title.login'))">
                                <small class="fa text-body" :class="is_login ? 'fa-table' : 'fa-user'"></small>
                            </a>
                        </li>
                        <li v-if="is_login" class="nav-item" style="padding-top: 6px;">
                            <a class="btn-sm-square bg-white rounded-circle ms-3" href="javascript:;" @click="onlogout" :title="$filters.capitalize($t('title.logout'))">
                                <small class="fa fa-lock text-body"></small>
                            </a>
                        </li>
                        
            
                    </ul>
                    </div>
            </nav>
        </div>
        
   
    <!-- Navbar End -->
</template>
<script>
import { checklogin, logout } from '@/api/appconfig';
import { list } from '@/api/permission';

// import { mapState } from "vuex";
export default {
    name: "NavBar",
    props: {
        video_categories: {
            type: Array,
            default(){
                return []
            }
        },
        news_categories: {
            type: Array,
            default(){
                return []
            }
        },
        product_categories: {
            type: Array,
            default(){
                return []
            }
        },
        contact_us: {
            type: Object,
            default(){
                return {}
            }
        },
    },
    data(){
        return {
            car_number: 0,
            is_login: false,
            permissions: []
        };
    },
    async created(){
        await this.getPermissions();
        let res = await checklogin();
        if (res != null && res.status == 200 && res.data != null) {
            if (res.data.data != null && res.data.message != 'Unauthorized') {
                this.$store.dispatch('setLoginServer', true);
            } else {
                this.$store.dispatch('setLoginServer', false);
            }
        } else {
            this.$store.dispatch('setLoginServer', false);
        }
    },
    async onMounted(){
        let res = await checklogin();
        if (res != null && res.status == 200 && res.data != null) {
            if (res.data.data != null && res.data.message != 'Unauthorized') {
                this.$store.dispatch('setLoginServer', true);
            } else {
                this.$store.dispatch('setLoginServer', false);
            }
        } else {
            this.$store.dispatch('setLoginServer', false);
        }
    },
    methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    async onlogout(){
        let res = await logout();
        if (res != null && res.status == 200) {
            this.$store.dispatch('setUserServer', {user: {}, login: false});
            this.$store.dispatch('permission/clearPermissions');
            this.$router.push({ path: '/' });
        }
    },
    async getPermissions(){
        let res = await list();
        if (res != null && res.status == 200 && res.data != null) {
            this.permissions = [];
        }
    }
  },
  watch: {
    '$store.state.cart': {
        handler(newCart){
            if (Array.isArray(newCart)) {
                this.car_number = 0;
                for(var i = 0; i < newCart.length; i++){
                    this.car_number += newCart[i].qty;
                }
            }
        },
        immediate: true,
        deep: true
    },
    '$store.state.islogin': {
        handler(newValue) {
            this.is_login = newValue;
            this.getPermissions();
        },
        immediate: true,
        deep: true
    }
  }
};
</script>
<style>
.nav-item-language {
    padding: 25px 15px !important;
    font-weight: 500;
    outline: none;
}
.navbar-top .navbar-nav .nav-link {
    padding: 0px 15px;
}
/* @media screen and (max-width: 989px) {
    #navbar-top {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    #navbar-top{
        display: none;
    }
} */
</style>