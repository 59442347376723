<template>
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <!-- Page Header Start -->
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.shop')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <sort-form :change-sort="changeSort" />
    <!-- Blog Start -->
    <div class="container-xxl py-3">
        <div class="container">
            <div class="row g-2">
                <div class="col-2 col-sidebarmenu">
                    <filter-form :is-category="false" :change-location="changeLocation" :change-ship-method="changeShipMethod" />
                </div>
                <div class="col-10 col-result">
                    <div class="row g-2">
                        <div v-for="(item, index) in shops" class="col-xl-2 col-lg-2 col-md-3 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                            <shop-item :prod="item" />
                    </div>
                    <pagination-index :page="search.page" :total="search.total" :page-size="search.pagesize" :change-page="changePage" :next-page="nextPage" :prev-page="prevPage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Blog End -->
</template>
<script>
import { list } from "@/api/shop";
import PaginationIndex from '../components/Pagination.vue'
import NavbarIndex from '@/components/NavbarIndex.vue'
import ShopItem from './components/ShopItem.vue'
import FilterForm from './components/FilterForm.vue'
import SortForm from './components/SortForm.vue'
import { api_backend } from '@/constants/configs';
export default {
    name: "ShopIndex",
    data() {
        return {
            shops: [],
            search: {
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: [],
                province_code: null,
                ship_id: 0,
                shop_category_id: 0,
            },
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        };
    },
    components: {
        PaginationIndex,
        NavbarIndex,
        ShopItem,
        FilterForm,
        SortForm
    },
    async created(){
        await this.getList();
    },
    methods: {
        async getList(){
            let query = 'page='+this.search.page+'&pagesize='+this.search.pagesize;
            if (this.search.keywords.trim() != '') {
                query += '&keywords='+this.search.keywords.trim()
            }
            if (this.search.province_code != null) {
                query += '&province_code='+this.search.province_code
            }
            if (this.search.sort.length > 0) {
                for(let i = 0; i < this.search.sort.length; i++) {
                    let arr = this.search.sort[i].split('-');
                    if (arr.length >= 2) {
                        query += '&sort['+arr[0]+']='+arr[1];
                    }
                }
            }
            let res = await list(query);
            if (res != null && res.status == 200 && res.data != null ){
                this.search.page = res.data.page;
                this.search.pagesize = res.data.pagesize;
                this.search.total = res.data.total;
                this.shops = res.data.data;
            }
        },
        changePage(value){
        if (this.search.page != value) {
            this.search.page = value;
            this.$router.push({ path: '/shop', query: this.search });
        }
        },
        nextPage(){
            this.search.page = Number(this.search.page)+1;
            this.$router.push({ path: '/shop', query: this.search });
        },

        prevPage(){
            this.search.page = Number(this.search.page)-1;
            this.$router.push({ path: '/shop', query: this.search });
        },

        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        async onSearch(){
            this.$router.push({ path: '/shop', query: { keywords: this.search.keywords, 
                page: this.search.page, 
                pagesize: this.search.pagesize, 
                sort: this.search.sort,
                province_code: this.search.province_code,
                ship_id: this.ship_id,
                shop_category_id: this.shop_category_id } });
        },
        onRefresh(){
            this.search = {
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: [],
                province_code: null,
                ship_id: 0,
                shop_category_id: 0,
            };
            this.$router.push({ path: '/shop', query: { keywords: this.search.keywords, page: this.search.page, 
                pagesize: this.search.pagesize, sort: this.search.sort,
                province_code: this.search.province_code,
                ship_id: this.search.ship_id,
                shop_category_id: this.search.shop_category_id } });

        },
        changeSort(e){
            this.search.sort = [];
            this.search.sort.push(e.target.value);
            this.$router.push({ path: '/shop', query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort,
            province_code: this.search.province_code,
            ship_id: this.search.ship_id,
            shop_category_id: this.search.shop_category_id } });
        },
        changeLocation(e){
            this.search.province_code = e.target.value;
        },
        changeShipMethod(e){
            this.search.ship_id = e.target.value;
        },
    },
    watch: {
        '$route'(to, from){
            console.log(to);
            console.log(from);
            this.getList();
        }
    },
}
</script>