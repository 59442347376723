import { createApp } from 'vue'
import App from './App.vue'
import VueCookies from 'vue3-cookies'
import router from './router'
import store from './store'
import FlashMessage from '@smartweb/vue-flash-message'
import CKEditor from '@ckeditor/ckeditor5-vue';
import i18n from './i18n';
const app = createApp(App)
app.use(VueCookies)
app.use(FlashMessage)
app.use(store)
app.use(i18n());
app.use(router)
app.use(CKEditor)
app.config.globalProperties.$filters = {
    capitalize(value){
        if (!value) return value;
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    showServiceRequestStatus(value){
        switch(value) {
            case 0:
                return 'created';
            case 1:
                return 'checking';
            case 2:
                return 'accepted';
            case 3:
                return 'rejected';
            default:
                return 'cancel';
        }
    },
};
// Mounting the application to an HTML element with id 'app'
app.mount('#app')
