import { createStore } from "vuex";
import csrf from './modules/csrf';
import permission from "./modules/permission";
import { getCart, setCart, removeCart, getLogin, setLogin, getUser, setUser, removeUser } from "@/utils/auth";
import { login } from "@/api/user";
import { logout } from "@/api/appconfig";
export default createStore({
  modules: {
    csrf,
    permission
  },
  plugins: [
  ],
  state: {
    isLoading: false,
    cart: getCart(),
    islogin: getLogin(),
    user: getUser(),
    loading: false,
  }, 
  // () {
  //   return {
  //     cart: getCart(),
  //     islogin: getLogin(),
  //     user: getUser(),
  //     isLoading: false,
  //     // permissions: []
  //   }
  // },
  mutations: {
    SET_CART: (state, cart) => {
      state.cart = cart
    },
    SET_LOGIN: (state, login) => {
      state.islogin = login
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    setLoading(state, val) {
      state.loading = val;
    }
  },
  actions: {
      addProductToCart({commit, state}, productInfo) {
        const { id, name, qty, selling_price, slug } = productInfo;
        //console.log(productInfo);
        if (!state.cart || state.cart.length <= 0) {
          let cart = [{ id: id, name: name, qty: qty, selling_price: selling_price, slug: slug }];
          setCart([{ id: id, name: name, qty: qty, selling_price: selling_price, slug: slug }])
          commit('SET_CART', cart);
        } else {
          let cart = state.cart;
          if (Array.isArray(cart) && cart.length > 0) {
            var add = false;
            for(var i = 0; i < cart.length; i++) {
              if (cart[i].id == id) {
                if (cart[i].qty + qty > 0) {
                  cart[i].qty = cart[i].qty + qty;
                  add = true;
                  break;
                } else {
                  cart.splice(i, 1);
                  add = true;
                  break;
                }
              }
            }
            if (!add) {
              cart.push({ id: id, name: name, qty: qty, selling_price: selling_price, slug: slug });
            }
            setCart(cart);
            commit('SET_CART', cart);
          }
        }
    },
    clearCart({commit}){
        setCart([]);
        removeCart();
        commit('SET_CART', [])
    },
    loginServer({commit}, loginForm){
      return new Promise((resolve, reject) => {
        login(loginForm).then(res => {
          if (res != null && res.status == 200) {
            setLogin(true);
            commit('SET_LOGIN', true);
            setUser(res.data);
            commit('SET_USER', res.data);
            resolve();
          }
        }).catch(err => {
          return reject(err);
        });
      });
    },
    logoutServer({commit}){
      return new Promise((resolve, reject) => {
        logout().then(res => {
          if (res != null && res.status == 200) {
            setLogin(false);
            commit('SET_LOGIN', false);
            removeUser();
            commit('SET_USER', {});
            resolve();
          }
        })
        .catch(err => {
          return reject(err);
        });
      });
    },
    setLoginServer({commit}, login){
      setLogin(login);
      commit('SET_LOGIN', login);
    },
    setUserServer({commit}, dataInfo){
      const {user, login} = dataInfo;
      setUser(user);
      commit('SET_USER', user);
      setLogin(login);
      commit('SET_LOGIN', login);
    }
  },
  getters: {
    csrf_token: state => state.csrf.csrf_token,
    cart: state => state.cart,
    islogin: state => state.islogin,
    user: state => state.user,
    permissions: state => state.permission.permissions
  }
});
