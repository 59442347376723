import request from '@/utils/request'
function add(data){
    return request({
        url: '/service-request',
        method: 'post',
        data
    });
}
function list(query){
    return request({
        url: '/service-request?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/service-request/'+id,
        method: 'get'
    });
}
function del(id){
    return request({
        url: '/service-request/'+id,
        method: 'delete'
    });
}
function edit(id, status){
    return request({
        url: '/service-request/'+id+'/'+status,
        method: 'put'
    });
}
export {list, detail, add, del, edit};