<template>
    <div class="container-xxl py-3" style="min-height: 60px;">
        <div class="container mshopes-seller-section" style="min-height: 60px;">
            <vue-horizontal class="g-2 p-0 seller-section-row float-end" responsive>
                <section class="seller-item-wrap me-1" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#partners">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/features/feature-1.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body seller-title">
                            <small>{{ $t('title.product-management') }}</small>
                        </div>
                    </a>
                </section>
                <section class="seller-item-wrap me-1" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#partners">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/features/feature-2.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body seller-title">
                            <small>{{ $t('title.order-management') }}</small>
                        </div>
                    </a>
                </section>
                <section class="seller-item-wrap me-1" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#partners">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/features/feature-3.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body seller-title">
                            <small>{{ $t('title.customer-management') }}</small>
                        </div>
                    </a>
                </section>
                <section class="seller-item-wrap" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#partners">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/features/feature-4.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body seller-title">
                            <small>{{ $t('title.supplier-partner-management') }}</small>
                        </div>
                    </a>
                </section>
                <section class="seller-item-wrap" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#partners">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/features/feature-5.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body seller-title">
                            <small>{{ $t('title.remote-store-management') }}</small>
                        </div>
                    </a>
                </section>
                <section class="seller-item-wrap" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#partners">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/features/feature-6.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body seller-title">
                            <small>{{ $t('title.manage-sales-campaigns') }}</small>
                        </div>
                    </a>
                </section>
                <section class="seller-item-wrap" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#partners">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/features/feature-7.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body seller-title">
                            <small>{{ $t('title.cost-management') }}</small>
                        </div>
                    </a>
                </section>
                <section class="seller-item-wrap" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="buyer-item" href="/gioi-thieu#partners">
                        <div class="position-relative overflow-hidden p-0 text-center">
                            <img class="img-thumbnail buyer-feature-image border" src="img/features/feature-8.png" alt="" style="max-width: 40px; height: 40px;">
                        </div>
                        <div class="text-center p-0 text-body seller-title">
                            <small>{{ $t('title.support-social-marketing') }}</small>
                        </div>
                    </a>
                </section>
            </vue-horizontal>
        </div>
    </div>
</template>
<script>
import VueHorizontal from "vue-horizontal";
export default {
    name: "SellerFeatures",
    components: {
        VueHorizontal
    }
}
</script>
<style>
.seller-feature-image {
    border-radius: 10px;
}
.seller-item-wrap {
    display: block;
    max-width: 90px;
    max-height: 120px;
    padding: 0 5px;
    border-right: 1px solid rgb(233, 229, 229);
}
.seller-title {
    text-transform: capitalize;
    line-height: 1.2;
    font-size: 14px;
}
</style>