import { getAppConfig, logout } from '@/api/appconfig';
import { getCsrfToken, setCsrfToken, removeCsrfToken } from '@/utils/auth'
// import { resetRouter } from '@/router'
const getDefaultState = () => {
    return {
        csrf_token: getCsrfToken(),
    }
};
const state = getDefaultState();
const mutations = {
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
    SET_CSRF_TOKEN: (state, csrf_token) => {
      state.csrf_token = csrf_token
    }
};
const actions = {
    // user login
    getCsrf({ commit }) {
      return new Promise((resolve, reject) => {
        getAppConfig().then(response => {
          const { data } = response;
          const { csrf } = data;
          commit('SET_CSRF_TOKEN', csrf);
          setCsrfToken(csrf);
          return resolve();
        }).catch(error => {
          return reject(error);
        })
      })
    },
    setCsrfServer({ commit }, csrf) {
      commit('SET_CSRF_TOKEN', csrf);
      setCsrfToken(csrf);
    },
    // user logout
    logout({ commit, state }) {
      console.log(state);
      return new Promise((resolve, reject) => {
        console.log(reject);
        logout().then(() => {
          removeCsrfToken() // must remove  token  first
          // resetRouter()
          commit('RESET_STATE')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // remove token
    resetToken({ commit }) {
      return new Promise(resolve => {
        removeCsrfToken() // must remove  token  first
        // resetRouter()
        commit('RESET_STATE')
        resolve()
      })
    },
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  };