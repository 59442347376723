<template>
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s" id="header-shop">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/shop">{{ $filters.capitalize($t('title.shop')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ shop.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <div v-if="shop.banner_image != null" class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s" id="shop-banner">
        <div class="container">
            <img class="img-fluid w-100" :src="host_url + shop.banner_image" />
        </div>
    </div>
    <div class="container-xxl pb-3">
        <div class="container">
            <div v-if="shop != null" class="row g-5 align-items-center">
                <div class="col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <div class="position-relative overflow-hidden p-5 pe-0 ps-0">
                        <img v-if="shop != null && shop.feature_image != null" class="img-fluid w-100" :src="host_url + shop.feature_image">
                    </div>
                </div>
                <div class="col-lg-9 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-4">{{ shop.name }}</h1>
                    <div v-if="typeof shop.address != 'undefined' && shop.address != null && shop.address.toString() != 'null'" class="mb-4">
                        <span class="text-primary me-1">{{ $filters.capitalize($t('title.address')) }}: </span>
                        <span class="text-body">{{ shop.address }}</span>
                    </div>
                    <div class="row">
                        <div class="form-group mb-2 col col-md-12">
                            <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('action.share')) }}: </span>
                            <a :href="'https://www.facebook.com/sharer/sharer.php?u=http://autoviet247.com/shop/'+shop.slug" type="button" class="btn btn-success me-2 btn-sm" target="_blank"><i class="fab fa-facebook"></i></a>
                            <a :href="'https://twitter.com/intent/tweet?text='+shop.name+'&url=http://autoviet247.com/shop/'+shop.slug+'&hashtags='+shop.keywords" type="button" class="btn btn-info me-2 btn-sm" target="_blank"><i class="fab fa-twitter"></i></a>
                        </div>
                    </div>
                    <div class="mb-4">
                        <span class="text-primary me-1">{{ $filters.capitalize($t('shop.owner')) }}: </span>
                        <span class="text-body">{{ shop.owner.name }}</span>
                    </div>
                    <div v-if="typeof shop.phone != 'undefined' && shop.phone != null  && shop.phone.toString() != 'null'" class="mb-4">
                        <span class="text-primary me-1">{{ $filters.capitalize($t('shop.phone')) }}: </span>
                        <span class="text-body">{{ shop.phone }}</span>
                    </div>
                    <div class="mb-4">
                        <span class="text-primary me-1">{{ $filters.capitalize($t('action.like')) }}: </span>
                        <span class="text-body me-2">{{ like_number }}</span>
                        <small class="w-50 text-center">
                            <a class="btn rounded-pill btn-success btn-sm me-2" href="javascript:;" @click="likeShop()"><i class="fa fa-regular fa-thumbs-up" :class="class_like"> {{ $filters.capitalize($t('title.like-shop')) }}</i></a>
                        </small>
                    </div>
                    <div class="mb-4">
                        <span class="text-primary me-1">{{ $filters.capitalize($t('action.follow')) }}: </span>
                        <span class="text-body me-2">{{ follow_number }}</span>
                        <small class="w-50 text-center">
                            <a class="btn rounded-pill btn-danger btn-sm me-2" href="javascript:;" @click="followShop()"><i class="fa fa-user-plus" :class="class_follow"> {{ $filters.capitalize($t('title.follow-shop')) }}</i></a>
                        </small>
                    </div>
                    <div v-if="typeof shop.website != 'undefined' && shop.website != null  && shop.website.toString() != 'null'" class="mb-4">
                        <span class="text-primary me-1">{{ $filters.capitalize($t('shop.website')) }}: </span>
                        <span class="text-body">{{ shop.website }}</span>
                    </div>
                    <div class="d-flex pt-2">
                        <a v-if="typeof shop.twitter != 'undefined' && shop.twitter != null  && shop.twitter.toString() != 'null'" class="btn btn-square btn-outline-dark rounded-circle me-1" :href="shop.twitter"><i class="fab fa-twitter"></i></a>
                        <a v-if="typeof shop.facebook != 'undefined' && shop.facebook != null  && shop.facebook.toString() != 'null'" class="btn btn-square btn-outline-dark rounded-circle me-1" :href="shop.facebook"><i class="fab fa-facebook-f"></i></a>
                        <a v-if="typeof shop.instagram != 'undefined' && shop.instagram != null  && shop.instagram.toString() != 'null'" class="btn btn-square btn-outline-dark rounded-circle me-1" :href="shop.instagram"><i class="fab fa-instagram"></i></a>
                        <a v-if="typeof shop.zalo != 'undefined' && shop.zalo != null  && shop.zalo.toString() != 'null'" class="btn btn-square btn-outline-dark rounded-circle me-1" :href="shop.zalo"><i class="fab fa-zalo"></i></a>
                        <a v-if="typeof shop.tiktok != 'undefined' && shop.tiktok != null  && shop.tiktok.toString() != 'null'" class="btn btn-square btn-outline-dark rounded-circle me-0" :href="shop.tiktok"><i class="fab fa-tiktok"></i></a>
                    </div>
                </div>
            </div>
            <div class="row g-4">
                <div class="d-flex border-top">
                    <h4 class="display-7 mb-3 pt-3">{{ $filters.capitalize($t('title.shop-introduction')) }}</h4>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-sx-12">
                    <p class="mb-4" v-html="shop.description"></p>
                </div>
            </div>
            <div class="row g-0 gx-5 align-items-end">
                <div class="col-lg-6">
                    <div class="section-header text-start mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                        <h4 class="display-7 mb-3">{{ $filters.capitalize($t('shop.products-of-shop')) }}</h4>
                    </div>
                </div>
            </div>
            <div class="row g-4">
                <form-sort :change-sort="changeSort" />
                <div v-for="(prod, index) in products" class="col-xl-2 col-lg-2 col-md-6 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <product-item :prod="prod" />
                </div>
                <pagination-index :page="search.page" :total="search.total" :page-size="search.pagesize" :change-page="changePage" :next-page="nextPage" :prev-page="prevPage" />
            </div>
        </div>
    </div>
    <!-- Product End -->
</template>
<script>
import PaginationIndex from '../components/Pagination.vue'
import NavbarIndex from '@/components/NavbarIndex.vue'
import ProductItem from '../product/components/ProductItem.vue'
import FormSort from '../product/components/FormSort.vue'
import {listByShop} from '@/api/product'
import {detail} from '@/api/shop'
import { api_backend } from '@/constants/configs';
import { add as addLike } from '@/api/like_shop';
import { add as addFollow } from '@/api/follow_shop';
export default {
    name: "ProductCategoryIndex",
    data() {
        return {
            shop: {
                owner: {},
                address: '',

            },
            products: [],
            search: { 
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: []
            },
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
            like_number: 0,
            follow_number: 0,
            class_like: 'text-default',
            class_follow: 'text-default',
            keywords: ''
        };
    },
    components: {
        PaginationIndex,
        NavbarIndex,
        ProductItem,
        FormSort
    },
    async created(){
        await this.getDetail();
        await this.getProducts();
    },
    methods: {
        addToCart(prod) {
            this.$store.dispatch('addProductToCart', {id: prod.id, name: prod.name, qty: 1, selling_price: prod.selling_price, slug: prod.slug});
            this.$flashMessage.show({
                type: 'success',
                title: this.$filters.capitalize(this.$t('title.success')),
                message: this.$filters.capitalize(this.$t('title.save-success')),
                time: 3000,
                group: 'cart'
            });
        },
        async getDetail(){
            let res = await detail(this.$route.params.slug);
            if (res != null && res.status == 200 && res.data != null ){
                this.shop = res.data;
                document.title = this.shop.name;
                const descEl = document.querySelector('head meta[name="description"]');
                descEl.setAttribute('content', this.shop.meta_description);
                const keywordscEl = document.querySelector('head meta[name="keywords"]');
                keywordscEl.setAttribute('content', this.shop.keywords);
                // set banner for shop
                // if (this.shop.banner_image != null) {
                //     const bannerEl = document.getElementById('header-shop');
                //     const bannerSrc = this.host_url + this.shop.banner_image.replace('public', this.backend+'/storage')
                //     if (bannerEl != null) {
                //         bannerEl.setAttribute("style", "background: url("+bannerSrc+") top right no-repeat; background-size: cover;");
                //     }
                // }
                // set facebook feature
                const titleElFacebook = document.querySelector('head meta[property="og:title"]');
                if (titleElFacebook != null) {
                    titleElFacebook.setAttribute('content', this.shop.name);
                }
                const descElFacebook = document.querySelector('head meta[property="og:description"]');
                if (descElFacebook != null) {
                    descElFacebook.setAttribute('content', this.shop.meta_description);
                }
                const urlElFacebook = document.querySelector('head meta[property="og:url"]');
                if (urlElFacebook != null) {
                    urlElFacebook.setAttribute('content', this.host_url+"shop/"+this.shop.slug);
                }
                const imageElFacebook = document.querySelector('head meta[property="og:image"]');
                if (imageElFacebook != null) {
                    imageElFacebook.setAttribute('content', this.host_url + this.shop.feature_image);
                }
                // set twitter feature
                const titleElTwitter= document.querySelector('head meta[name="twitter:title"]');
                if (titleElTwitter != null) {
                    titleElTwitter.setAttribute('content', this.shop.name);
                }
                const descElTwitter = document.querySelector('head meta[name="twitter:description"]');
                if (descElTwitter != null) {
                    descElTwitter.setAttribute('content', this.shop.meta_description);
                }
                const imageElTwitter = document.querySelector('head meta[name="twitter:image"]');
                if (imageElTwitter != null) {
                    imageElTwitter.setAttribute('content', this.host_url + this.shop.feature_image);
                }
            }
        },
        async getProducts(){
            let query = 'page='+this.search.page+'&pagesize='+this.search.pagesize;
            if (this.search.keywords.trim() != '') {
                query += '&keywords='+this.search.keywords.trim()
            }
            if (this.search.sort.length > 0) {
                for(let i = 0; i < this.search.sort.length; i++) {
                    let arr = this.search.sort[i].split('-');
                    if (arr.length >= 2) {
                        query += '&sort['+arr[0]+']='+arr[1];
                    }
                }
            }
            let res = await listByShop(query, this.$route.params.slug);
            if (res != null && res.status == 200 && res.data != null ){
                this.search.page = res.data.page;
                this.search.pagesize = res.data.pagesize;
                this.search.total = res.data.total;
                this.products = res.data.data;
                console.log(this.products);
            }
        },
        changePage(value){
        if (this.search.page != value) {
            this.search.page = value;
            this.$router.push({ path: '/shop/chi-tiet/'+this.$route.params.slug, query: this.search });
        }
        },
        nextPage(){
            this.search.page = Number(this.search.page)+1;
            this.$router.push({ path: '/shop/chi-tiet/'+this.$route.params.slug, query: this.search });
        },

        prevPage(){
            this.search.page = Number(this.search.page)-1;
            this.$router.push({ path: '/shop/chi-tiet/'+this.$route.params.slug, query: this.search });
        },

        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        async onSearch(){
            if (this.search.keywords.trim() != '') {
                this.$router.push({ path: '/shop/chi-tiet/'+this.$route.params.slug, query: this.search });
            }
        },
        changeSort(e){
            this.search.sort = [];
            this.search.sort.push(e.target.value);
            this.$router.push({ path: '/shop/chi-tiet/'+this.$route.params.slug, query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort } });
        },
        onRefresh(){
            this.search.keywords = '';
            this.$router.push({ path: '/shop/chi-tiet/'+this.$route.params.slug });

        },
        async likeShop() {
            if (!this.is_login) {
                this.$router.push({ path: '/login' });
                return;
            }
            if (this.shop != null && this.shop.id > 0) {
                let res = await addLike({
                    shop_id: this.shop.id
                });
                if (res != null && res.status == 200 && res.data != null) {
                    this.like_number = res.data.length;
                    if (res.data.length > 0) {
                        let found = res.data.filter(el => el.user_id == this.$store.state.user.id);
                        if (found && found.length > 0) {
                            this.class_like = 'text-success';
                        } else {
                            this.class_like = 'text-default';
                        }
                    } else {
                        this.is_like = 'text-default';
                    }
                    this.$flashMessage.show({
                        type: 'success',
                        title: this.$filters.capitalize(this.$t('title.success')),
                        message: this.$filters.capitalize(this.$t('title.save-success')),
                        time: 3000,
                        group: 'cart'
                    });
                } else {
                    this.$flashMessage.show({
                        type: 'error',
                        title: this.$filters.capitalize(this.$t('title.failure')),
                        message: this.$filters.capitalize(this.$t('title.save-failure')),
                        time: 3000,
                        group: 'cart'
                    });
                }
            }
        },
        async followShop() {
            if (!this.is_login) {
                this.$router.push({ path: '/login' });
                return;
            }
            if (this.shop != null && this.shop.id > 0) {
                let res = await addFollow({
                    shop_id: this.shop.id
                });
                if (res != null && res.status == 200 && res.data != null) {
                    this.follow_number = res.data.length;
                    if (res.data.length > 0) {
                        let found = res.data.filter(el => el.user_id == this.$store.state.user.id);
                        if (found && found.length > 0) {
                            this.class_follow = 'text-success';
                        } else {
                            this.class_follow = 'text-default';
                        }
                    } else {
                        this.class_follow = 'text-default';
                    }
                    this.$flashMessage.show({
                        type: 'success',
                        title: this.$filters.capitalize(this.$t('title.success')),
                        message: this.$filters.capitalize(this.$t('title.save-success')),
                        time: 3000,
                        group: 'cart'
                    });
                } else {
                    this.$flashMessage.show({
                        type: 'error',
                        title: this.$filters.capitalize(this.$t('title.failure')),
                        message: this.$filters.capitalize(this.$t('title.save-failure')),
                        time: 3000,
                        group: 'cart'
                    });
                }
            }
        },
    },
    watch: {
        '$route'(to, from){
            console.log(to);
            console.log(from);
            // console.log(from);
            // var params = to.fullPath.split('?');
            // var query = '';
            // if (params.length > 1) {
            //     query = params.slice(-1);
            // }
            this.getProducts();
        },
        '$store.state.islogin': {
            handler(newValue) {
                this.is_login = newValue;
            },
            immediate: true,
            deep: true
        }
    },
}
</script>
<style>
.about-img {
    max-width: 300px;
}
#shop-banner {
    max-height: 160px;
}
#shop-banner img {
    max-height: 160px;
}
</style>