<template>
    <!-- Page Header Start -->
    <div class="container-fluid mt-5 py-3 wow fadeIn border-top" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 mb-3 animated slideInDown d-none">{{ $filters.capitalize($t('title.about-us')) }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.about-us')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <firm-visit :firmvisit="about_us.firmvisit" />
</template>
<script>
import FirmVisit from './components/FirmVisit.vue'
import { getAppConfig } from '@/api/appconfig';

export default {
    name: "AboutPage",
    data(){
        return {
            about_us: {
                firmvisit: {}
            },
            features: []
        }
    },
    components: {
        FirmVisit,
    },
    async created(){
        let res = await getAppConfig();
        if (res != null && res.status == 200 && res.data != null) {
            document.title = res.data.app_config.title;
            const descEl = document.querySelector('head meta[name="description"]');
            descEl.setAttribute('content', res.data.app_config.meta_description);
            const keywordscEl = document.querySelector('head meta[name="keywords"]');
            keywordscEl.setAttribute('content', res.data.app_config.keywords);
            this.about_us = JSON.parse(res.data.app_config.about_us);
            this.features = JSON.parse(res.data.app_config.features);
        }
    }
}
</script>