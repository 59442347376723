<template>
    <!-- Page Header Start -->
    <div class="container-fluid my-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 animated slideInDown">{{ order.product.name }} : {{ order.product.shop.name }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/manager/service-request">{{ $filters.capitalize($t('title.service-request')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ order.product.name }}</li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="container-fluid bg-icon mt-5">

        <div class="container">

            <div class="row align-items-center">
                <form>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="code">{{ $filters.capitalize($t('title.name')) }}</label>
                                <input v-model="order.product.name" type="text" class="form-control-plaintext" id="code" readonly>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="order_date">{{ $filters.capitalize($t('order.order-date')) }}</label><br/>
                                <span>
                                {{ (new Date(order.book_datetime * 1000)).toLocaleDateString() +' ' + (new Date(order.book_datetime * 1000)).toLocaleTimeString() }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="shop_name">{{ $filters.capitalize($t('title.shop')) }}</label>
                                <input v-model="order.product.shop.name" type="text" class="form-control-plaintext" id="shop_name" readonly>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="user_email">{{ $filters.capitalize($t('title.email')) }}</label>
                                <input v-model="order.email" type="text" class="form-control-plaintext" id="user_email" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="receive_phone">{{ $filters.capitalize($t('title.phone')) }}</label>
                                <input v-model="order.phone" type="text" class="form-control-plaintext" id="receive_phone" readonly>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="ship_address">{{ $filters.capitalize($t('title.address')) }}</label>
                                <input v-model="order.address" type="text" class="form-control-plaintext" id="ship_address" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-12">
                            <h6 class="display-7 animated slideInDown">{{ $filters.capitalize($t('title.service-option')) }}</h6>
                        </div>
                    </div>
                    <div class="row g-3">
                        <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{ $filters.capitalize($t('title.name')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('title.cost')) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in order.service_option_requests" v-bind:key="index">
                            <th scope="row">{{ index+1 }}</th>
                            <td>{{ item.service_option.name }}</td>
                            <td>{{ item.service_option.selling_price }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="total">{{ $filters.capitalize($t('title.before-tax-cost')) }}</label>
                                <input v-model="order.before_tax_cost" type="number" class="form-control-plaintext" id="total" readonly>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="total">{{ $filters.capitalize($t('title.after-tax-cost')) }}</label>
                                <input v-model="order.after_tax_cost" type="number" class="form-control-plaintext" id="total" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="notes">{{ $filters.capitalize($t('order.notes')) }}</label>
                                <input v-model="order.notes" type="text" class="form-control-plaintext" id="notes" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="status">{{ $filters.capitalize($t('order.status')) }}</label>
                                <input :value="$t('action.'+$filters.showServiceRequestStatus(order.status))" type="text" class="form-control-plaintext" id="notes" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 mt-1">
                        <div v-if="order.status != 4" class="col-6 container d-flex align-items-center justify-content-center">
                            <button class="btn btn-primary btn-sm rounded-pill py-1 px-3" type="button" @click="cancel">{{ $filters.capitalize($t('action.cancel')) }}</button>
                        </div>
                        <div class="col-6 container d-flex align-items-center justify-content-center">
                            <a class="btn btn-success btn-sm rounded-pill py-1 px-3" type="button" href="/manager/service-request">{{ $filters.capitalize($t('action.back')) }}</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import {detail, del} from '@/api/service_request'
export default {
    name: "ServiceRequestEdit",
    data(){
        return {
            order: {
                user: {},
                product: {
                    shop: {}
                },
                after_tax_cost: 0,
                before_tax_cost: 0,
                service_option_requests: []
            },
            listStatus: [
                {
                    val: 0,
                    label: 'new'
                },
                {
                    val: 1,
                    label: 'processing'
                },
                {
                    val: 2,
                    label: 'accepted'
                },
                {
                    val: 3,
                    label: 'rejected'
                },
                {
                    val: 4,
                    label: 'cancel'
                },
            ],
            status: 0,
            total: 0
        }
    },

    created(){
        this.getDetail();
    },

    methods: {
        async getDetail() {
            this.$store.state.isLoading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.isLoading = false;
            if (res != null && res.status == 200) {
                this.order = res.data;
                this.status = this.order.status;
            }
        },

        async cancel(){
            this.$store.state.isLoading = true;
            let res = await del(this.$route.params.id);
            this.$store.state.isLoading = false;
            if (res != null && res.status == 200) {
                this.$router.push({ path: '/manager/service-request' });
                this.$flashMessage.show({
                        type: 'success',
                        title: this.$filters.capitalize(this.$t('title.success')),
                        message: this.$filters.capitalize(this.$t('title.updated-success')),
                        time: 3000,
                        group: 'order'
                    });
            } else {
                this.$flashMessage.show({
                        type: 'error',
                        title: this.$filters.capitalize(this.$t('title.failure')),
                        message: this.$filters.capitalize(this.$t('title.updated-failure')),
                        time: 3000,
                        group: 'order'
                    });
            }
        },
    }
};

</script>

<style>

</style>