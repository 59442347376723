<template>
    <!-- Page Header Start -->
    <div class="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-3 mb-3 animated slideInDown">{{ $filters.capitalize($t('error.error-403')) }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="#">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('error.error-403')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- 404 Start -->
    <div class="container-xxl py-6 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                    <h1 class="display-1">403</h1>
                    <h1 class="mb-4">{{ $filters.capitalize($t('error.unauthentication')) }}</h1>
                    <p class="mb-4">{{ $filters.capitalize($t('error.please-contact-us-to-get-advisory')) }}</p>
                    <a class="btn btn-primary rounded-pill py-3 px-5" href="/">{{ $filters.capitalize($t('action.back')) }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- 404 End -->
</template>
<script>
export default {
    name: "403Page"
}
</script>