import request from '@/utils/request'
function list(query){
    return request({
        url: '/short-video?'+query,
        method: 'get'
    });
}
function listByCategory(query, categoryId){
    return request({
        url: '/short-video-by-category/'+categoryId+'?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/short-video/'+id,
        method: 'get'
    });
}
function listTop(query){
    return request({
        url: '/short-video-top?'+query,
        method: 'get'
    });
}
function related(id){
    return request({
        url: '/short-video/'+id+'/related',
        method: 'get'
    });
}
export {list, detail, listByCategory, listTop, related};