<template>
    <!-- Page Header Start -->
    <div class="container-fluid mt-5 py-4 wow fadeIn border-top" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.register')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- Contact Start -->
    <div class="container-xxl py-6">
        <div class="container">
            <div class="row g-5 justify-content-center">
                <div class="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                    <form>
                        <div class="form-group row g-3 mt-1">
                            <label for="email" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.email')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="email" type="email" class="form-control form-control-sm" id="email" placeholder="Your Email" @click="clearError">
                                <span class="error text-danger">{{ errorEmail }}</span>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="fullname" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.name')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="fullname" type="text" class="form-control form-control-sm" id="fullname" placeholder="Ten cua ban">
                                <span class="error text-danger">{{ errorFullname }}</span>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="password" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.password')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="password" type="password" class="form-control form-control-sm" id="password" placeholder="">
                                <span class="error text-danger">{{ errorPassword }}</span>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="confirm_password" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.confirm-password')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="confirm_password" type="password" class="form-control form-control-sm" id="confirm_password" placeholder="">
                                <span class="error text-danger">{{ errorConfirmPassword }}</span>
                            </div>
                        </div>
                        <div class="row g-3 mt-1">
                            <div class="col-12 container d-flex align-items-center justify-content-center">
                                <button class="btn btn-primary rounded-pill py-1 px-3 btn-sm" type="button" @click="submitRegister" >{{ $filters.capitalize($t('title.register')) }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
</template>
<script>
import {register} from '@/api/user'
export default {
    name: "RegisterPage",
    data(){
        return {
            isLoading: false,
            email: "",
            password: "",
            confirm_password: "",
            haveError: false,
            errorEmail: "",
            errorPassword: "",
            errorConfirmPassword: "",
            errorFullname: ""
        }
    },
    async created(){
        await this.$store.dispatch('csrf/getCsrf');
        if (this.$store.getters.csrf_token) {
            document.title = "Register";
            const csrfEl = document.querySelector('head meta[name="csrf-token"]');
            csrfEl.setAttribute('content', this.$store.getters.csrf_token);
        }
    },
    methods: {
        async submitRegister(){
            if (this.email.trim() == '') {
                this.haveError = true;
                this.errorEmail = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.email'));
                return;
            }
            if (!this.isValidEmail()) {
                this.haveError = true;
                this.errorEmail = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.email'));
                return;
            }
            if (this.password.trim() == '') {
                this.haveError = true;
                this.errorPassword = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.password'));
                return;
            }
            if (this.confirm_password.trim() == '') {
                this.haveError = true;
                this.errorConfirmPassword = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.password'));
                return;
            }
            if (this.confirm_password.trim() != this.password.trim()) {
                this.haveError = true;
                this.errorConfirmPassword = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.confirm-password'));
                return;
            }
            if (this.fullname.trim() == '') {
                this.haveError = true;
                this.errorFullname = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.name'));
                return;
            }
            let data = new FormData();
            data.append('email', this.email);
            data.append('password', this.password.trim());
            data.append('fullname', this.fullname.trim());
            let res = await register(data);
            if (res != null && res.status == 200 && res.data != null) {
                this.$flashMessage.show({
                    type: 'success',
                    title: this.$filters.capitalize(this.$t('title.success')),
                    message: this.$filters.capitalize(this.$t('title.register-success')),
                    time: 3000,
                    group: 'register'
                });
                this.$router.push({ path: '/' });
            } else {
                this.$flashMessage.show({
                    type: 'error',
                    title: this.$filters.capitalize(this.$t('title.failure')),
                    message: this.$filters.capitalize(this.$t('title.register-failure')),
                    time: 3000,
                    group: 'register'
                });
            }
        },
        clearError(){
            this.errorEmail = '';
            this.errorPassword = '';
            this.haveError = false;
        },
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
        }
    }
};
</script>
<style>
</style>