<template>
    <nav id="sidebarMenu" class="d-lg-block sidebar bg-white">
      <div class="position-sticky">
        <div class="list-group list-group-flush mx-0 mt-0">
          <!-- Collapse 1 -->
          <a class="list-group-item list-group-item-action py-2" aria-current="true" href="/blog">
              <span>{{ $filters.capitalize($t('news.categories')) }}</span>
          </a>
          <!-- Collapsed content -->
          <ul id="ul-categories" class="list-group list-group-flush p-0" v-if="!isCategory">
            <li v-for="(item, index) in categories" class="list-group-item py-2 px-0 d-md-inline-block" v-bind:key="index">
                  <a :href="'/blog/'+item.slug" class="text-reset d-flex">
                      <img v-if="item.icon != null" class="img-thumbnail" style="width: 40px; height: 40px;" :src=" host_url + item.icon.replace('public', backend+'/storage')" alt="NoImage">
                      <small class="ps-1">{{ item.name}}</small>
                  </a>
            </li>
          </ul>
          <!-- Collapse 1 -->
          <label class="list-group-item list-group-item-action py-2">{{ $filters.capitalize($t('news.public-time'))}}</label>
          <ul id="ul-prices" class="list-group list-group-flush p-0">
              <li class="list-group-item py-2 px-0">
                <div class="form-group">
                  <label>{{ $filters.capitalize($t('title.from_date'))}}</label>
                  <input type="datetime-local" class="form-control form-control-sm d-flex" :value="from_date" :placeholder="$filters.capitalize($t('title.from_date'))" @change="changeFromDate">
                </div>
                <div class="form-group">
                  <label>{{ $filters.capitalize($t('title.to_date'))}}</label>
                  <input type="datetime-local" class="form-control form-control-sm d-flex" :value="to_date" :placeholder="$filters.capitalize($t('title.to_date'))" @change="changeToDate">
                </div>
              </li>
            </ul>
        </div>
      </div>
    </nav>
  </template>
  <script>
  import {list} from '@/api/news_category';
  import { api_backend } from '@/constants/configs';
  export default {
      name: "FilterForm",
      props: [
        'changeFromDate',
        'changeToDate',
        'isCategory'
      ],
      data(){
          return {
              categories: [],
              host_url: process.env.VUE_APP_BASE_HOST,
              backend: api_backend,
              from_date: '',
              to_date: '',
          };
      },
      created(){
          this.getCategory();
      },
      methods: {
          async getCategory() {
              let res = await list('page=1&pagesize=100');
              if (res != null && res.status == 200 && res.data != null) {
                  this.categories = res.data.data;
              }
          },
      },
  };
  </script>
  <style>
  #sidebarMenu {
    border-right: solid 1px rgb(228, 226, 226);
  }
  </style>