<template>
    <div class="container-xxl py-3" style="min-height: 150px;">
        <div class="container mshopes-section" style="min-height: 150px;">
            <div class="mshopes-header-section pb-3 border-bottom">
                <div class="title">
                    {{ $filters.capitalize($t('news.categories')) }}
                </div>
            </div>
            <vue-horizontal class="g-2 p-0 product-row" responsive>
                <section v-for="(item, index) in categories" class=" p-0 product-item-wrap" data-wow-delay="0.1s" v-bind:key="index">
                    <a class="product-category-item" :href="'/san-pham/'+item.slug">
                        <div class="position-relative overflow-hidden p-2">
                            <img v-if="item.icon != null" class="img-fluid w-100" :src=" host_url + item.icon" alt="NoImage">
                        </div>
                        <div class="text-center p-0 text-body title">
                            {{ item.name }}
                        </div>
                    </a>
                </section>
            </vue-horizontal>
        </div>
    </div>
</template>
<script>
import VueHorizontal from "vue-horizontal";
import {list} from '@/api/product_category';
import { api_backend } from '@/constants/configs';
export default {
    name: "ProductCategory",
    data(){
        return {
            categories: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        }
    },
    components: {
        VueHorizontal
    },
    created(){
        this.getCategory();
    },
    methods: {
        async getCategory() {
            let res = await list('page=1&pagesize=100');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        }
    },
}
</script>
<style>
.mshopes-header-section-content .row {
    width: 1820px !important;
}
.product-item-wrap {
    width: 120px !important;
    border-bottom: 1px solid rgb(228, 223, 223);
    border-right: 1px solid rgb(228, 223, 223);
}
.product-item-wrap:last-child {
    border-right: none;
}
.product-category-item {
    display: block;
}
.mshopes-header-section .title {
    text-transform: uppercase;
}
.mshopes-header-section-content .title {
    text-transform: uppercase;
}
.product-category-item .title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
}
.v-hl-svg {
    background: #f0ebeb !important;
    color: #3CB815 !important;
}
@media screen and (max-width: 768px) {
    
}
</style>