const about_page = {
    register_button: {
        title: 'signup-now',
        url: '/register' 
    },
    register_content: {
        title: 'welcome-customers-to-register-us-at-the-registration-page'
    },
    customers: {
        title: 'our-commitment',
        content: 'our-goal-is-to-provide-the-best-service-to-our-customers',
        features: [
            {
                title: 'best',
                content: 'genuine-goods-right-brand-right-product',
                image: 'img/commitments/commit-1.png'
            },
            {
                title: 'fast',
                content: 'easy-ordering-automatic-order-processing-quick-delivery',
                image: 'img/commitments/commit-2.png'
            },
            {
                title: 'devoted',
                content: '24h7-online-support-reputable-after-sale-service',
                image: 'img/commitments/commit-3.png'
            }
        ]
    },
    partner_button: {
        title: 'signup-now',
        url: '/lien-he' 
    },
    partner_content: {
        title: 'become-our-partner',
        content: 'welcome-to-become-a-reliable-sales-partner-with-us'
    },
    partners: {
        title: 'online-shop-management',
        content: 'our-goal-is-to-provide-the-best-bisiness-management-to-our-partners',
        features: [
            {
                title: 'product-management',
                content: 'product-management-content',
                image: '/img/features/feature-1.png'
            },
            {
                title: 'order-management',
                content: 'order-management-content',
                image: '/img/features/feature-2.png'
            },
            {
                title: 'customer-management',
                content: 'customer-management-content',
                image: '/img/features/feature-3.png'
            },
            {
                title: 'supplier-partner-management',
                content: 'supplier-partner-management-content',
                image: '/img/features/feature-4.png'
            },
            {
                title: 'remote-store-management',
                content: 'remote-store-management',
                image: '/img/features/feature-5.png'
            },
            {
                title: 'manage-sales-campaigns',
                content: 'manage-sales-campaigns-content',
                image: '/img/features/feature-6.png'
            },
            {
                title: 'cost-management',
                content: 'cost-management-content',
                image: '/img/features/feature-7.png'
            },
            {
                title: 'support-social-marketing',
                content: 'support-social-marketing-content',
                image: '/img/features/feature-8.png'
            },
        ]
    }
};
export {
    about_page
};