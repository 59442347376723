<template>
        <div class="container-fluid wow fadeIn" data-wow-delay="0.1s">
            <div class="container">
    <form class="form-inline pt-0">
        <div class="row">
            <div class="form-group mb-2 col-md-4">
                <label>
                    {{$filters.capitalize($t('action.sort'))}}
                </label>
            </div>
            <div class="form-group mb-2 col-md-4">
                <select class="form-control form-control-sm select-sort" :placeholder="$filters.capitalize($t('action.sort'))" @change="changeSort">
                    <option v-for="(item, index) in sorts" v-bind:key="item.value+index.toString()" :value="item.value">{{ $filters.capitalize($t('sort.'+item.label)) }}</option>
                </select>
            </div>
        </div>
    </form>
    </div>
    </div>
</template>
<script>
import {shop_sorts} from '@/constants/configs';
export default {

    name: "SortForm",

    data(){

        return {
            sorts: shop_sorts
        };

    },

    props: [
        'changeSort'
    ],

    methods: {

    }

};

</script>
<style>
select.form-control.select-sort {
    background-color: #ffffff !important;
}
</style>