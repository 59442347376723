<template>
    <!-- Page Header Start -->
    <div class="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 mb-3 animated slideInDown">{{ $filters.capitalize($t('title.verify')) }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="#">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.verify')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- 404 Start -->
    <div class="container-xxl py-6 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <i class="bi display-6" :class="result == 'OK' ? 'bi-check text-primary' : 'bi-exclamation text-danger'"></i>
                    <h1 class="display-6">{{ $filters.capitalize($t('title.confirm-email')) }}</h1>
                    <h1 class="mb-4">{{ result }}</h1>
                    <a class="btn btn-primary btn-sm rounded-pill py-2 px-5" href="/">{{ $filters.capitalize($t('action.back')) }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- 404 End -->
</template>
<script>
import {verifyEmail} from '@/api/user'
export default {
    name: "VerifyPage",
    data(){
        return {
            result: "",
        }
    },
    async created(){
        await this.$store.dispatch('csrf/getCsrf');
        if (this.$store.getters.csrf_token) {
            document.title = "Register";
            const csrfEl = document.querySelector('head meta[name="csrf-token"]');
            csrfEl.setAttribute('content', this.$store.getters.csrf_token);
        }
        var query = this.$route.fullPath;
        let result = query.replace("emaiverify", "email/verify");
        let res = await verifyEmail(result);
        if (res != null && res.status == 200 && res.data != null) {
            this.result = res.data.message;
        }
    }
}
</script>