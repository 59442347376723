<template>
    <!-- Firm Visit Start -->
    <div class="container-fluid bg-primary bg-icon py-3">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-md-7 wow fadeIn" data-wow-delay="0.1s">
                    <h4 class="display-7 text-white mb-3">{{ $filters.capitalize($t('title.register')) }}</h4>
                    <p class="text-white mb-0">{{ $filters.capitalize($t('title.'+about_page.register_content.title)) }}</p>
                </div>
                <div class="col-md-5 text-md-end wow fadeIn" data-wow-delay="0.5s">
                    <a class="btn btn-lg btn-secondary rounded-pill py-2 px-3 btn-sm" href="/register">{{ $filters.capitalize($t('title.'+about_page.register_button.title)) }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-light bg-icon py-3" id="customers">
        <div class="container">
            <div class="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                <h4 class="display-7 mb-3">{{ $filters.capitalize($t('title.'+about_page.customers.title)) }}</h4>
                <p>{{ $filters.capitalize($t('title.'+about_page.customers.content)) }}</p>
            </div>
            <div class="row g-4">
                <div v-for="(item, index) in about_page.customers.features" v-bind:key="index" class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="bg-white text-center h-100 p-4 p-xl-5">
                        <img v-if="item.image != null " class="img-fluid mb-4" :src="item.image" alt="" style="width: 87px;">
                        <h4 class="mb-3">{{ $filters.capitalize($t('title.'+item.title)) }}</h4>
                        <p class="mb-4">{{ $filters.capitalize($t('title.'+item.content)) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Firm Visit End -->
    <!-- Partners -->
    <div class="container-fluid bg-primary bg-icon py-3">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-md-7 wow fadeIn" data-wow-delay="0.1s">
                    <h4 class="display-7 text-white mb-3">{{ $filters.capitalize($t('title.'+about_page.partner_content.title)) }}</h4>
                    <p class="text-white mb-0">{{ $filters.capitalize($t('title.'+about_page.partner_content.content)) }}</p>
                </div>
                <div class="col-md-5 text-md-end wow fadeIn" data-wow-delay="0.5s">
                    <a class="btn btn-lg btn-secondary rounded-pill py-2 px-3 btn-sm" :href="about_page.partner_button.url">{{ $filters.capitalize($t('title.'+about_page.partner_button.title)) }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-light bg-icon py-3" id="partners">
        <div class="container">
            <div class="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                <h4 class="display-7 mb-3">{{ $filters.capitalize($t('title.'+about_page.partners.title)) }}</h4>
                <p>{{ $filters.capitalize($t('title.'+about_page.partners.content)) }}</p>
            </div>
            <div class="row g-4">
                <div v-for="(item, index) in about_page.partners.features" v-bind:key="index" class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="bg-white text-center h-100 p-4 p-xl-5">
                        <img v-if="item.image != null " class="img-fluid mb-4" :src="item.image" alt="" style="width: 87px;">
                        <h4 class="mb-3">{{ $filters.capitalize($t('title.'+item.title)) }}</h4>
                        <p class="mb-4">{{ $filters.capitalize($t('title.'+item.content)) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Partners End-->
</template>
<script>
import { about_page } from '@/constants/about_us';
export default {
    name: "FirmVisit",
    props: {
        firmvisit: {
            type: String,
            default(){
                return "";
            }
        }
    },
    data(){
        return {
            host_url: process.env.VUE_APP_BASE_HOST,
            about_page: about_page
        }
    }
};
</script>