<template>
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
<!-- Page Header Start -->
<div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
    <div class="container">
        <h1 class="display-6 mb-3 animated slideInDown">{{ $filters.capitalize($t('action.order-car')) }}</h1>
        <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('action.order-car')) }}</li>
            </ol>
        </nav>
    </div>
</div>
<!-- Page Header End -->
<!-- Contact Start -->
<div class="container-xxl py-6">
    <div class="container">
        <div class="row g-5 justify-content-center">
            <div class="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                <h5 class="mb-4 display-7 justify-content-center">{{ $filters.capitalize($t('title.order-information')) }}</h5>
                <form>
                    <div class="row g-4">
                        <div class="product-item">
                            <div class="text-center p-4">
                                <a class="d-block h5 mb-2" :href="'/san-pham/detail/'+service.slug">{{ service.name }}</a>
                            </div>
                            <div class="position-relative bg-light overflow-hidden">
                                <img v-if="service.feature_image != null" class="img-fluid w-100" :src="host_url + service.feature_image" alt="NoImage">
                                <div class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">{{ $filters.capitalize($t('title.new')) }}</div>
                            </div>
                            <div class="d-flex border-top">
                                <h4 class="display-7 mb-3 pt-3">{{ $filters.capitalize($t('title.product-introduction')) }}</h4>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-sx-12">
                                <p class="mb-4" v-html="service.description"></p>
                            </div>
                        </div>
                        <div class="col-12 text-left wow fadeInUp" data-wow-delay="0.1s">
                            <p><i class="fa fa-soild fa-dollar-sign text-secondary me-3"></i>{{ $filters.capitalize($t('order.total-money')) }}: <span class="text-secondary text-bold">{{ total.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span></p>
                        </div>
                    </div>
                    <div v-if="!is_login" class="form-group row g-3 mt-1">
                        <label for="email" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.email')) }}</label>
                        <div class="col-sm-10">
                            <input v-model="email" type="email" class="form-control form-control-sm" id="email" placeholder="Your Email" @click="clearError">
                            <span class="error text-danger">{{ errorEmail }}</span>
                        </div>
                    </div>
                    <div v-if="!is_login" class="form-group row g-3 mt-1">
                        <label for="fullname" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.name')) }}</label>
                        <div class="col-sm-10">
                            <input v-model="fullname" type="text" class="form-control form-control-sm" id="fullname" placeholder="" @click="clearError">
                            <span class="error text-danger">{{ errorFullname }}</span>
                        </div>
                    </div>
                    <div class="form-group row g-3 mt-1">
                        <label for="phone" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.phone')) }}</label>
                        <div class="col-sm-10">
                            <input v-model="phone" type="text" class="form-control form-control-sm" id="phone" placeholder="" @click="clearError">
                            <span class="error text-danger">{{ errorPhone }}</span>
                        </div>
                    </div>
                    <div class="form-group row g-3 mt-1">
                        <label for="address" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.address')) }}</label>
                        <div class="col-sm-10">
                            <input v-model="address" type="text" class="form-control form-control-sm" id="address" placeholder="" @click="clearError">
                            <span class="error text-danger">{{ errorAddress }}</span>
                        </div>
                    </div>
                    <div class="form-group row g-3 mt-1">
                        <label for="book_datetime" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.datetime')) }}</label>
                        <div class="col-sm-10">
                            <input v-model="book_datetime" type="datetime-local" class="form-control form-control-sm" id="book_datetime" placeholder="" @click="clearError">
                            <span class="error text-danger">{{ errorDatetime }}</span>
                        </div>
                    </div>
                    <div class="form-group row g-3 mt-1">
                        <label for="notes" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('order.notes')) }}</label>
                        <div class="col-sm-10">
                            <input v-model="notes" type="text" class="form-control form-control-sm" id="notes" placeholder="" @click="clearError">
                            <span class="error text-danger">{{ errorNotes }}</span>
                        </div>
                    </div>
                    <div class="row g-3 mt-1">
                        <div class="col-6 container d-flex align-items-center justify-content-center">
                            <button class="btn btn-primary rounded-pill py-2 px-3 btn-sm" type="button" @click="submitOrder">{{ $filters.capitalize($t('action.send')) }}</button>
                        </div>
                        <div class="col-6 container d-flex align-items-center justify-content-center">
                            <a v-if="!is_login" class="btn btn-success rounded-pill py-2 px-3 btn-sm" type="button" href="/login">{{ $filters.capitalize($t('title.login')) }}</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Contact End -->
</template>
<script>
import {add as bookService} from '@/api/service_request'
import {detail} from '@/api/product'
import NavbarIndex from '@/components/NavbarIndex.vue';
export default {
name: "BookCarSale",
data(){
    return {
        is_login: false,
        email: '',
        address: '',
        phone: '',
        fullname: '',
        errorAddress: '',
        errorEmail: '',
        errorFullname: '',
        errorPhone: '',
        haveError: false,
        notes: '',
        errorNotes: '',
        errorDatetime: '',
        book_datetime: '',
        search: {
            keywords: '',
            page: 1,
            pagesize: 30,
            total: 0
        },
        service: {
            description: '',
            meta_description: '',
            shop: {},
            selling_price: 0
        },
        total: 0,
        host_url: process.env.VUE_APP_BASE_HOST,
    };
},
components: {
    NavbarIndex
},
created(){
    this.getProduct();
},
methods: {
    async getProduct() {
        if (this.$route.params.id > 0) {
            let res = await detail(this.$route.params.id);
            if (res != null && res.status == 200 && res.data != null ){
                this.service = res.data;
                this.total = this.service.selling_price;
            }
        }
    },
    async submitOrder(){
        if (!this.is_login) {
            if (this.email.trim() == '') {
                this.haveError = true;
                this.errorEmail = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.email'));
                return;
            }
            if (!this.isValidEmail()) {
                this.haveError = true;
                this.errorEmail = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.email'));
                return;
            }
            if (this.fullname.trim() == '') {
                this.haveError = true;
                this.errorFullname = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.name'));
                return;
            }
            
        }
        if (this.phone.trim() == '') {
                this.haveError = true;
                this.errorPhone = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.phone'));
                return;
            }
        if (this.address.trim() == '') {
                this.haveError = true;
                this.errorAddress = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.address'));
                return;
            }
        if (this.notes.trim() == '') {
                this.haveError = true;
                this.errorNotes = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('order.notes'));
                return;
            }
        if (this.book_datetime.trim() == '') {
            this.haveError = true;
            this.errorDatetime = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.datetime'));
            return;
        }
        let date = new Date(this.book_datetime);
        if (date == null || !(date instanceof Date)) {
            return;
        }
        let data = new FormData();
        if (!this.is_login) {
            data.append('email', this.email);
            data.append('fullname', this.fullname);    
        }
        data.append('phone', this.phone);
        data.append('address', this.address);
        data.append('notes', this.notes);
        data.append('product_id', this.service.id);
        data.append('book_datetime', date/1000);
        this.$store.state.isLoading = true;
        let res = await bookService(data);
        this.$store.state.isLoading = false;
        if (res != null && res.status == 200 && res.data != null) {
            this.$flashMessage.show({
                type: 'success',
                title: this.$filters.capitalize(this.$t('title.success')),
                message: this.$filters.capitalize(this.$t('title.save-success')),
                time: 3000,
                group: 'order'
            });
            this.$router.push({ path: '/' });
        } else {
            this.$flashMessage.show({
                type: 'error',
                title: this.$filters.capitalize(this.$t('title.failure')),
                message: this.$filters.capitalize(this.$t('title.save-success')),
                time: 3000,
                group: 'order'
            });
        }
    },
    clearError(){
        this.errorEmail = '';
        this.errorPassword = '';
        this.errorAddress = '';
        this.errorFullname = '';
        this.errorNotes = '';
        this.errorPhone = '';
        this.errorDatetime = '';
        this.haveError = false;
    },
    isValidEmail() {
        return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
    },
    changeKeyword(e) {
        this.search.keywords = e.target.value;
    },
    onSearch() {
        this.$router.push({path: '/san-pham', query: this.search});
    },
    changeOptions(e){
        let options = e.target.options;
        let value = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        if (value && value.length > 0) {
            let amount = 0;
            for(let i = 0; i < value.length; i++) {
                let found = this.service_options.filter(e => e.id = value[i]);
                if (found) {
                    amount += found[0].selling_price;
                }
            }
            this.total = this.service.selling_price + amount;
        }
        this.service_option_ids = value;
    }
},
watch: {
'$store.state.cart': {
    handler(newCart){
        if (Array.isArray(newCart)) {
            this.total = 0;
            for(var i = 0; i < newCart.length; i++){
                this.total += Number(newCart[i].selling_price) * Number(newCart[i].qty);
            }
        }
        this.carts = newCart;
    },
    immediate: true,
    deep: true
},
'$store.state.islogin': {
    handler(newValue) {
        this.is_login = newValue;
    },
    immediate: true,
    deep: true
}
}
};
</script>
<style>
</style>