<template>
    
    <!-- Page Header Start -->
    <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/san-pham">{{ $filters.capitalize($t('title.product')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ ProductDetail.name }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- About Start -->
    <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5 align-items-center">
                <!-- Carousel Start -->
                <div class="container-fluid col-lg-6 p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div v-for="(item, index) in ProductDetail.images"  class="carousel-item" :class="index == 0? 'active': ''" v-bind:key="index">
                                <img v-if="item.name != null" class="w-100" :src="host_url + item.name" alt="Image">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $filters.capitalize($t('action.prev')) }}</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $filters.capitalize($t('action.next')) }}</span>
                        </button>
                    </div>
                </div>
                <!-- Carousel End -->
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="display-6 mb-4">{{ ProductDetail.name }}</h1>
                    <p v-for="(prod, index) in ProductDetail.features" v-bind:key="index"><i class="fa fa-check text-primary me-3"></i>{{ prod }}</p>
                    <div v-if="ProductDetail.shop != null" class="mb-4">
                        <span class="text-primary me-1">{{ $filters.capitalize($t('title.shop')) }}: </span>
                        <a class="btn btn-danger rounded-pill btn-sm" :href="'/shop/'+ProductDetail.shop.slug">{{ ProductDetail.shop.name }}</a>
                    </div>
                    <div v-if="ProductDetail.prod_product_categories != null" class="d-flex pt-2 mb-4">
                        <small v-for="(prod, index) in ProductDetail.prod_product_categories" class="text-center border-end px-2" v-bind:key="index">
                            <a class="btn btn-primary rounded-pill btn-sm" :href="'/san-pham/'+prod.product_category.slug">{{ prod.product_category.name }}</a>
                        </small>
                    </div>
                    <div class="mb-4">
                        <span v-if="ProductDetail.selling_price != null && ProductDetail.selling_price > 0" class="text-primary me-1">{{ ProductDetail.selling_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                        <span v-else class="text-danger me-1 text-responsive">{{ $filters.capitalize($t('title.contact')) }}</span>
                        <span v-if="ProductDetail.show_price != null && ProductDetail.show_price > 0" class="text-body text-decoration-line-through">{{ ProductDetail.show_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) }}</span>
                    </div>
                    <div class="row">
                        <div class="form-group mb-2 col col-md-12">
                            <span class="display-7 mb-3 me-2">{{ $filters.capitalize($t('action.share')) }}: </span>
                            <a :href="'https://www.facebook.com/sharer/sharer.php?u=http://autoviet247.com/san-pham/detail/'+ProductDetail.slug" type="button" class="btn btn-success me-2 btn-sm" target="_blank"><i class="fab fa-facebook"></i></a>
                            <a :href="'https://twitter.com/intent/tweet?text='+ProductDetail.name+'&url=http://autoviet247.com/san-pham/detail/'+ProductDetail.slug+'&hashtags='+ProductDetail.keywords" type="button" class="btn btn-info me-2 btn-sm" target="_blank"><i class="fab fa-twitter"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="ProductDetail.category_type_id == 0 && !ProductDetail.category_ids.includes(1)" class="row g-4">
                <div class="d-flex border-top">
                    <small class="w-100 text-center border-end py-2">
                        <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm mt-3" href=""><i class="fa fa-shopping-cart text-white me-2"></i>{{ $filters.capitalize($t('action.buy-now')) }}</a>
                    </small>
                    <small class="w-100 text-center py-2">
                        <a class="btn btn-secondary rounded-pill py-2 px-3 btn-sm mt-3" href="javascript:;" @click="addToCart(ProductDetail)"><i class="fa fa-cart-plus text-white me-2"></i>{{ $filters.capitalize($t('action.add-cart')) }}</a>
                    </small>
                </div>
            </div>
            <div v-if="ProductDetail.category_type_id == 0 && ProductDetail.category_ids.includes(1)" class="row g-4">
                <div class="d-flex border-top">
                    <small class="w-100 text-center border-end py-2">
                        <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm mt-3" :href="'/book-car-sale/'+ProductDetail.id">
                            <i class="fa fa-calendar-check text-white me-2"></i>{{ $filters.capitalize($t('action.order-car')) }}
                        </a>
                    </small>
                </div>
            </div>
            <div v-if="ProductDetail.category_type_id == 1" class="row g-4">
                <div class="d-flex border-top">
                    <small class="w-100 text-center border-end py-2">
                        <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm mt-3" :href="'/book-service/'+ProductDetail.id">
                            <i class="fa fa-calendar-check text-white me-2"></i>{{ $filters.capitalize($t('action.schedule-now')) }}
                        </a>
                    </small>
                </div>
            </div>
            <div class="row g-4">
                <div class="d-flex border-top">
                    <h4 class="display-7 mb-3 pt-3">{{ $filters.capitalize($t('title.product-introduction')) }}</h4>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-sx-12">
                    <p class="mb-4" v-html="ProductDetail.description"></p>
                </div>
            </div>
            <div class="row g-0 gx-5 align-items-end">
                <div class="col-lg-6">
                    <div class="section-header text-start mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                        <h4 class="display-7 mb-3">{{ $filters.capitalize($t('title.related-products')) }}</h4>
                    </div>
                </div>
            </div>
            <div class="row g-4">
                <div v-for="(prod, index) in products" class="col-xl-2 col-lg-2 col-md-6 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                    <product-item :prod="prod" />
                </div>
                <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <a class="btn btn-primary rounded-pill py-2 px-3 btn-sm" href="/san-pham">{{ $filters.capitalize($t('action.more-products')) }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>
<script>
import { detail, list, related } from '@/api/product';
import { api_backend } from '@/constants/configs';
import ProductItem from './components/ProductItem.vue'
// import BuyerFeatures from '../components/BuyerFeatures.vue'
import NavbarIndex from '@/components/NavbarIndex.vue'
export default {
    name: "ProductDetail",
    components: {
        ProductItem,
        // BuyerFeatures,
        NavbarIndex
    },
    data() {
        return {
            ProductDetail: {
                description: '',
                meta_description: '',
                shop: {},
                category_ids: [],
                category_type_id: 1
            },
            products: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
            keywords: ''
        }
    },
    async created(){
        let res = await detail(this.$route.params.slug);
        if (res != null && res.status == 200 && res.data != null ){
            console.log(res.data);
            this.ProductDetail = res.data;
            this.ProductDetail.features = JSON.parse(res.data.features);
            this.ProductDetail.category_ids = [];
            for(let i = 0; i < res.data.prod_product_categories.length; i++){
                this.ProductDetail.category_ids.push(res.data.prod_product_categories[i].product_category_id);
            }
            document.title = this.ProductDetail.name;
            const descEl = document.querySelector('head meta[name="description"]');
            descEl.setAttribute('content', this.ProductDetail.meta_description);
            const keywordscEl = document.querySelector('head meta[name="keywords"]');
            keywordscEl.setAttribute('content', this.ProductDetail.keywords);
            // set facebook feature
            const titleElFacebook = document.querySelector('head meta[property="og:title"]');
            if (titleElFacebook != null) {
                titleElFacebook.setAttribute('content', this.ProductDetail.name);
            }
            const descElFacebook = document.querySelector('head meta[property="og:description"]');
            if (descElFacebook != null) {
                descElFacebook.setAttribute('content', this.ProductDetail.meta_description);
            }
            const urlElFacebook = document.querySelector('head meta[property="og:url"]');
            if (urlElFacebook != null) {
                urlElFacebook.setAttribute('content', this.host_url+"san-pham/detail/"+this.ProductDetail.slug);
            }
            const imageElFacebook = document.querySelector('head meta[property="og:image"]');
            if (imageElFacebook != null) {
                imageElFacebook.setAttribute('content', this.host_url + this.ProductDetail.feature_image.replace('public', this.backend+'/storage'));
            }
            // set twitter feature
            const titleElTwitter= document.querySelector('head meta[name="twitter:title"]');
            if (titleElTwitter != null) {
                titleElTwitter.setAttribute('content', this.ProductDetail.name);
            }
            const descElTwitter = document.querySelector('head meta[name="twitter:description"]');
            if (descElTwitter != null) {
                descElTwitter.setAttribute('content', this.ProductDetail.meta_description);
            }
            const imageElTwitter = document.querySelector('head meta[name="twitter:image"]');
            if (imageElTwitter != null) {
                imageElTwitter.setAttribute('content', this.host_url + this.ProductDetail.feature_image);
            }
            let resRelated = await related(this.ProductDetail.id);
            if (resRelated != null && resRelated.status == 200 && resRelated.data != null && resRelated.data.data != null && resRelated.data.data.length > 0 ){
                this.products = resRelated.data.data;
                console.log(resRelated);
            } else {
                let resList = await list('page=1&pagesize=4');
                if (resList != null && resList.status == 200 && resList.data != null ){
                    this.products = resList.data.data;
                }
            }
        }
    },
    methods: {
        addToCart(prod) {
            this.$store.dispatch('addProductToCart', {id: prod.id, name: prod.name, qty: 1, selling_price: prod.selling_price, slug: prod.slug, feature_image: this.host_url + prod.feature_image});
            this.$flashMessage.show({
                type: 'success',
                title: this.$filters.capitalize(this.$t('title.success')),
                message: this.$filters.capitalize(this.$t('title.save-success')),
                time: 3000,
                group: 'cart'
            });
        },
        changeKeyword(e){
            this.keywords = e.target.value;
        },
        onSearch(){
            this.$router.push({ path: '/san-pham', query: { keywords: this.keywords } });
        }
    }
}
</script>