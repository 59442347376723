<template>
    <div class="container-xxl py-3" style="min-height: 600px;">
        <div class="container mshopes-section" style="min-height: 600px;">
            <div class="mshopes-header-section pb-3 border-bottom">
                <div class="title d-flex">
                    <div class="w-50" data-wow-delay="0.1s">
                        {{ $filters.capitalize($t('title.business-establishment')) }}
                    </div>
                    <div class="w-50 align-items-end justify-content-end d-flex" data-wow-delay="0.1s">
                        <a class="btn btn-default btn-sm py-2 px-3" href="/shop">
                            {{ $filters.capitalize($t('action.more-shops')) }}
                            <i class="fa fa-angle-right text-success ms-1"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <vue-horizontal v-if="banners.length > 0" class="slider-shop col-xs-6 col-sm-6" resposive>
                    <section v-for="(item, index) in banners" class="p-0" v-bind:key="index">
                        <a :href="item.url">
                            <img v-if="item.image" class="w-100" :src="host_url + item.image" alt="Image loading Error" />
                        </a>
                    </section>
                </vue-horizontal>
                <div class="shop-row col-xs-6 col-sm-6" :class="banners.length > 0 ? 'shop-row' : 'shop-row-max'">
                    <div class="row g-2 mt-3">
                        <div v-for="(prod, index) in shops" class="col-xl-2 col-lg-2 col-md-3 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                            <a class="product-item" :href="'/shop/chi-tiet/'+prod.slug">
                                <div class="position-relative bg-light overflow-hidden">
                                    <img v-if="prod.feature_image != null" class="img-fluid w-100" :src="host_url + prod.feature_image" alt="NoImage">
                                </div>
                                <div class="text-body p-1">
                                    <a class="d-block h6 mb-2 lh-base text-body text-responsive" :href="'/shop/chi-tiet/'+prod.slug">{{ prod.name }}</a>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueHorizontal from "vue-horizontal";
import {listTop} from '@/api/shop';
import {list as listBanner} from '@/api/banner';
import { api_backend } from '@/constants/configs';
export default {
    name: "ShopIndexHome",
    data(){
        return {
            shops: [],
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
            banners: []
        }
    },
    async created(){
        let res = await listTop('page=1&pagesize=30');
        if (res != null && res.status == 200 && res.data != null) {
            this.shops = res.data.data
        }
        this.getBanner();
    },
    components: {
        VueHorizontal
    },
    methods: {
        async getBanner() {
            this.banners = [];
            let res = await listBanner('page=1&pagesize=4&type_id=2');
            if (res != null && res.status == 200 && res.data != null && res.data.data != null) {
                for (let i = 0; i< res.data.data.length; i++) {
                    this.banners.push(res.data.data[i]);
                }
            }
            res = await listBanner('page=1&pagesize=4&type_id=3');
            if (res != null && res.status == 200 && res.data != null && res.data.data != null) {
                for (let i = 0; i< res.data.data.length; i++) {
                    this.banners.push(res.data.data[i]);
                }
            }
            console.log(this.banners);
        }
    },
}
</script>
<style>
.shop-row {
    width: 1112px;
}
.shop-row-max {
    width: 1320px;
}
.shop-wrap {
    width: 120px !important;
    border-bottom: 1px solid rgb(228, 223, 223);
    border-right: 1px solid rgb(228, 223, 223);
}
.shop-wrap:last-child {
    border-right: none;
}
.shop-item {
    display: block;
}
.mshopes-header-section .title {
    text-transform: uppercase;
}
.mshopes-header-section-content .title {
    text-transform: uppercase;
}
.product-category-item .title {
    text-transform: uppercase;
}
.v-hl-svg {
    background: #f0ebeb !important;
    color: #3CB815 !important;
}
.slider-shop {
    max-height: 600px;
    width: 160px;
}
@media screen and (max-width: 768px) {
    
}
</style>