<template>
    <!-- Page Header Start -->
    <div class="container-fluid mt-5 py-4 wow fadeIn border-top" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.login')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <!-- Contact Start -->
    <div class="container-xxl py-6">
        <div class="container">
            <div class="row g-5 justify-content-center">
                <div class="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                    <p class="mb-4">{{ $filters.capitalize($t('title.login')) }}</p>
                    <form>
                        <div class="form-group row g-3 mt-1">
                            <label for="email" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.email')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="email" type="email" class="form-control form-control-sm" id="email" placeholder="Your Email" @click="clearError">
                                <span class="error text-danger">{{ errorEmail }}</span>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <label for="password" class="col-sm-2 col-form-label col-form-label-sm">{{ $filters.capitalize($t('title.password')) }}</label>
                            <div class="col-sm-10">
                                <input v-model="password" type="password" class="form-control form-control-sm" id="password" placeholder="" @click="clearError">
                                <span class="error text-danger">{{ errorPassword }}</span>
                            </div>
                        </div>
                        <div class="form-group row g-3 mt-1">
                            <div class="col-4 container d-flex align-items-center justify-content-center">
                                <button class="btn btn-primary rounded-pill py-1 px-3 btn-sm" type="button" @click="submitLogin">{{ $filters.capitalize($t('title.login')) }}</button>
                            </div>
                            <div class="col-4 container d-flex align-items-center justify-content-center">
                                <a class="btn rounded-pill py-1 px-3 btn-sm" type="button" :href="host_url+backend+'/login/google'">
                                    <i style="font-size:24px" class="fab text-danger">&#xf1a0;</i> {{ $filters.capitalize($t('title.signing-google')) }}
                                </a>
                            </div>
                            <div class="col-4 container d-flex align-items-center justify-content-center">
                                <a class="btn btn-success rounded-pill py-1 px-3 btn-sm" type="button" href="/register">{{ $filters.capitalize($t('title.register')) }}</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
</template>
<script>
import { login } from '@/api/user';
import { api_backend } from '@/constants/configs';

export default {
    name: "LoginPage",
    data(){
        return {
            email: "",
            password: "",
            haveError: false,
            errorEmail: "",
            errorPassword: "",
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        }
    },
    async created(){
        await this.$store.dispatch('csrf/getCsrf');
        if (this.$store.getters.csrf_token) {
            document.title = "Login";
            const csrfEl = document.querySelector('head meta[name="csrf-token"]');
            csrfEl.setAttribute('content', this.$store.getters.csrf_token);
        }
    },
    methods: {
        async submitLogin(){
            if (this.email.trim() == '') {
                this.haveError = true;
                this.errorEmail = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.email'));
                return;
            }
            if (!this.isValidEmail()) {
                this.haveError = true;
                this.errorEmail = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.email'));
                return;
            }
            if (this.password.trim() == '') {
                this.haveError = true;
                this.errorPassword = this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$filters.capitalize(this.$t('title.password'));
                return;
            }
            let data = new FormData();
            data.append('email', this.email);
            data.append('password', this.password);
            data.append('remember', true);
            this.$store.state.isLoading = true;
            let res = await login(data);
            this.$store.state.isLoading = false;
            if (res != null && res.status == 200 && res.data != null) {
                // this.$store.dispatch('setLoginServer', true);
                this.$store.dispatch('setUserServer', {user: res.data, login: true});
                this.$store.dispatch('csrf/setCsrfServer', res.data.csrf_token);
                // this.$store.dispatch('permission/permissionsServer');
                this.$flashMessage.show({
                    type: 'success',
                    title: this.$filters.capitalize(this.$t('title.success')),
                    message: this.$filters.capitalize(this.$t('title.login-success')),
                    time: 3000,
                    group: 'login'
                });
                this.$router.push({ path: '/' });

            } else {
                this.$flashMessage.show({
                    type: 'error',
                    title: this.$filters.capitalize(this.$t('title.failure')),
                    message: this.$filters.capitalize(this.$t('title.login-failure')),
                    time: 3000,
                    group: 'login'
                });
            }
        },
        clearError(){
            this.errorEmail = '';
            this.errorPassword = '';
            this.haveError = false;
        },
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
        },
        async submitLoginGoogle(){

        }
    },
};
</script>
<style>
</style>