<template>
        <navbar-index :change-keywords="changeKeyword" :click-search="onSearch" />
    <!-- Page Header Start -->
    <div class="container-fluid my-3 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.short-video')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->
    <sort-form :change-sort="changeSort" />
    <!-- Blog Start -->
    <div class="container-xxl py-3">
        <div class="container">
            <div class="row g-2">
                <div class="col-2 col-sidebarmenu">
                    <filter-form :is-category="false" :change-from-date="changeFromDate" :change-to-date="changeToDate" />
                </div>
                <div class="col-10 col-result">
                    <div class="row g-2">
                        <div v-for="(prod, index) in news" class="col-xl-2 col-lg-2 col-md-6 col-xs-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" v-bind:key="index">
                            <video-item :item="prod" />
                        </div>
                        <pagination-index :page="search.page" :total="search.total" :page-size="search.pagesize" :change-page="changePage" :next-page="nextPage" :prev-page="prevPage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Blog End -->
</template>
<script>
import { list } from "@/api/short_video";
import PaginationIndex from '../components/Pagination.vue'
import NavbarIndex from "@/components/NavbarIndex.vue";
import SortForm from "./components/SortForm.vue";
import FilterForm from "./components/FilterForm.vue";
import VideoItem from "./components/VideoItem.vue";
import { api_backend } from '@/constants/configs';
export default {
    name: "NewsCategoryIndex",
    data() {
        return {
            news: [],
            category: {},
            search: {
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: [],
                from_date: '',
                to_date: ''
            },
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend
        };
    },
    components: {
        PaginationIndex,
        NavbarIndex,
        VideoItem,
        SortForm,
        FilterForm
    },
    async created(){
        await this.getList();
    },
    methods: {
        async getList(){
            let query = 'page='+this.search.page+'&pagesize='+this.search.pagesize;
            if (this.search.keywords.trim() != '') {
                query += '&keywords='+this.search.keywords.trim()
            }
            if (this.search.from_date.trim() != '') {
                let from_date = new Date(this.search.from_date);
                if (from_date instanceof Date) {
                    query += '&from_date='+Math.floor(from_date.getTime() / 1000);
                }
            }
            if (this.search.to_date.trim() != '') {
                let to_date = new Date(this.search.to_date);
                if (to_date instanceof Date) {
                    query += '&to_date='+Math.floor(to_date.getTime() / 1000);
                }
            }
            if (this.search.sort.length > 0) {
                for(let i = 0; i < this.search.sort.length; i++) {
                    let arr = this.search.sort[i].split('-');
                    if (arr.length >= 2) {
                        query += '&sort['+arr[0]+']='+arr[1];
                    }
                }
            }
            let res = await list(query);
            if (res != null && res.status == 200 && res.data != null ){
                this.search.page = res.data.page;
                this.search.pagesize = res.data.pagesize;
                this.search.total = res.data.total;
                this.news = res.data.data;
            }
        },
        changePage(value){
        if (this.search.page != value) {
            this.search.page = value;
            this.$router.push({ path: '/short-video', query: this.search });
        }
        },
        nextPage(){
            this.search.page = Number(this.search.page)+1;
            this.$router.push({ path: '/short-video', query: this.search });
        },

        prevPage(){
            this.search.page = Number(this.search.page)-1;
            this.$router.push({ path: '/short-video', query: this.search });
        },

        changeKeyword(e) {
            this.search.keywords = e.target.value;
        },
        async onSearch(){
            this.$router.push({ path: '/short-video', 
            query: { keywords: this.search.keywords, 
                page: this.search.page, 
                pagesize: this.search.pagesize,
                 sort: this.search.sort,
                from_date: this.search.from_date, to_date: this.search.to_date } });
        },
        onRefresh(){
            this.search = {
                page: 1,
                pagesize: 30,
                keywords: '',
                total: 0,
                sort: []
            };
            this.$router.push({ path: '/short-video', query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort, from_date: this.search.from_date, to_date: this.search.to_date }});
        },
        changeSort(e){
            this.search.sort = [];
            this.search.sort.push(e.target.value);
            this.$router.push({ path: '/short-video', query: { keywords: this.search.keywords, page: this.search.page, pagesize: this.search.pagesize, sort: this.search.sort, from_date: this.search.from_date, to_date: this.search.to_date } });
        },
        changeFromDate(e) {
            this.search.from_date = e.target.value;
        },
        changeToDate(e) {
            this.search.to_date = e.target.value;
        },
    },
    watch: {
        '$route'(to, from){
            console.log(to);
            console.log(from);
            // console.log(from);
            // var params = to.fullPath.split('?');
            // var query = '';
            // if (params.length > 1) {
            //     query = params.slice(-1);
            // }
            this.getList();
        }
    },
}
</script>