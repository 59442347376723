<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination">
            <li class="page-item" v-if="page > 1">
                <a class="page-link" href="javascript:;" @click="prevPage" aria-label="Trước">
                    <i class="fa fa-angle-left"></i>
                    <span class="sr-only">Trước</span>
                </a>
            </li>
            <li class="page-item" v-if="page > 3 && Math.ceil(total/pageSize) > 10">
                <a class="page-link" href="javascript:;" @click="changePage(page - 3)">...</a>
            </li>
            <li v-for="item in Math.ceil(total/pageSize)" v-bind:key="item"  class="page-item" :class="item==page ? 'active' : (Math.ceil(total/pageSize) > 10 && (Math.abs(item-page) <=3) ? '': 'd-none') ">
                <a class="page-link" href="javascript:;" @click="changePage(item)">{{ item }}</a>
            </li>
            <li class="page-item" v-if="page < Math.ceil(total/pageSize) - 3 && Math.ceil(total/pageSize) > 10">
                <a class="page-link" href="javascript:;" @click="changePage(page + 3)">...</a>
            </li>
            <li class="page-item" v-if="page < Math.ceil(total/pageSize)">
                <a class="page-link" href="javascript:;" @click="nextPage" aria-label="Tiếp">
                    <i class="fa fa-angle-right"></i>
                    <span class="sr-only">Tiếp</span>
                </a>
            </li>
        </ul>
    </nav>
</template>
<script>
export default {
    name: 'PaginationIndex',
    props: [
        'page',
        'pageSize',
        'total',
        'changePage',
        'nextPage',
        'prevPage'
    ],
};
</script>