<template>
    <!-- Page Header Start -->
    <div class="container-fluid my-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 animated slideInDown">{{ order.code }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item"><a class="text-body" href="/manager/order">{{ $filters.capitalize($t('title.order')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ order.code }}</li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="container-fluid bg-icon mt-5">

        <div class="container">

            <div class="row align-items-center">

                <form>

                    <div class="row g-3">

                        <div class="col-md-6">

                            <div class="form-group">

                                <label for="code">{{ $filters.capitalize($t('order.order-code')) }}</label>

                                <input v-model="order.code" type="text" class="form-control-plaintext" id="code" readonly>

                            </div>

                        </div>

                        <div class="col-6">

                            <div class="form-group">

                                <label for="order_date">{{ $filters.capitalize($t('order.order-date')) }}</label><br/>

                                <span>

                                {{ (new Date(order.order_date * 1000)).toLocaleDateString() +' ' + (new Date(order.order_date * 1000)).toLocaleTimeString() }}

                                </span>

                            </div>

                        </div>

                    </div>

                    <div class="row g-3">

                        <div class="col-md-6">

                            <div class="form-group">

                                <label for="user_name">{{ $filters.capitalize($t('title.fullname')) }}</label>

                                <input v-model="order.user.name" type="text" class="form-control-plaintext" id="user_name" readonly>

                                

                            </div>

                        </div>

                        <div class="col-6">

                            <div class="form-group">

                                <label for="user_email">{{ $filters.capitalize($t('title.email')) }}</label>

                                <input v-model="order.user.email" type="text" class="form-control-plaintext" id="user_email" readonly>

                                

                            </div>

                        </div>

                    </div>

                    <div class="row g-3">

                        <div class="col-md-6">

                            <div class="form-group">

                                <label for="receive_phone">{{ $filters.capitalize($t('order.receive-phone')) }}</label>

                                <input v-model="order.receive_phone" type="text" class="form-control-plaintext" id="receive_phone" readonly>

                                

                            </div>

                        </div>

                        <div class="col-6">

                            <div class="form-group">

                                <label for="ship_address">{{ $filters.capitalize($t('order.receive-address')) }}</label>

                                <input v-model="order.ship_address" type="text" class="form-control-plaintext" id="ship_address" readonly>

                                

                            </div>

                        </div>

                    </div>

                    <div class="row g-3">

                        <table class="table">

                        <thead>

                            <tr>

                                <th scope="col">#</th>

                                <th scope="col">{{ $filters.capitalize($t('title.name')) }}</th>

                                <th scope="col">{{ $filters.capitalize($t('title.quantity')) }}</th>

                                <th scope="col">{{ $filters.capitalize($t('product.selling-price')) }}</th>

                                <th scope="col">{{ $filters.capitalize($t('order.total-money')) }}</th>

                                <th scope="col">{{ $filters.capitalize($t('order.notes')) }}</th>

                            </tr>

                        </thead>

                        <tbody>

                        <tr v-for="(item, index) in order.order_details" v-bind:key="index">

                            <th scope="row">{{ index+1 }}</th>

                            <td>{{ item.product.name }}</td>

                            <td>{{ item.qty }}</td>

                            <td>{{ item.product.selling_price }}</td>

                            <td>{{ item.product.selling_price * item.qty }}</td>

                            <td>{{ item.notes }}</td>

                        </tr>

                        </tbody>

                    </table>

                    </div>

                    <div class="row g-3">

                        <div class="col-md-6">

                            <div class="form-group">

                                <label for="total">{{ $filters.capitalize($t('order.total-money')) }}</label>

                                <input v-model="total" type="text" class="form-control-plaintext" id="total" readonly>

                            </div>

                        </div>

                        <div class="col-6">

                            <div class="form-group">

                                <label for="notes">{{ $filters.capitalize($t('order.total-money')) }}</label>

                                <input v-model="order.notes" type="text" class="form-control-plaintext" id="notes" readonly>

                            </div>

                        </div>

                    </div>

                    <div class="row g-3">

                        <div class="col-md-6">

                            <div class="form-group">

                                <label for="payment_method">{{ $filters.capitalize($t('order.payment-method')) }}</label>

                                <input v-model="order.payment_method" type="text" class="form-control-plaintext" id="payment_method" readonly>

                                

                            </div>

                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="status">{{ $filters.capitalize($t('order.status')) }}</label>
                                <select class="form-control form-control-sm" placeholder="Chọn trạng thái" v-model="status">
                                    <option v-for="(item, index) in listStatus" v-bind:key="item.toString()+index.toString()" :value="index">{{ item }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row g-3 mt-1">

                        <div class="col-6 container d-flex align-items-center justify-content-center">

                            <button class="btn btn-primary btn-sm rounded-pill py-1 px-3" type="button" @click="updateStatus">{{ $filters.capitalize($t('action.save')) }}</button>

                        </div>

                        <div class="col-6 container d-flex align-items-center justify-content-center">

                            <a class="btn btn-success btn-sm rounded-pill py-1 px-3" type="button" href="/manager/order">{{ $filters.capitalize($t('action.back')) }}</a>

                        </div>

                    </div>

                </form>

            </div>

        </div>

    </div>

</template>

<script>

import {detail, edit} from '@/api/order'

import {listStatus} from '@/api/shop'

export default {

    name: "OrderEdit",

    data(){

        return {

            order: {

                user: {},

                shop: {},

                total: 0

            },

            listStatus: [],

            status: 0,

            total: 0

        }

    },

    created(){

        this.getDetail();

        this.getStatus();

    },

    methods: {

        async getDetail() {

            let res = await detail(this.$route.params.id);

            if (res != null && res.status == 200) {

                this.order = res.data;

                let total = 0;

                for(var i =0; i<this.order.order_details.length; i++) {

                    total += this.order.order_details[i].product.selling_price*this.order.order_details[i].qty;

                }

                this.total = total;

                this.status = this.order.status;

            }

        },

        async updateStatus(){

            let res = await edit(this.$route.params.id, this.status);

            if (res != null && res.status == 200) {

                this.$flashMessage.show({

                        type: 'success',

                        title: this.$filters.capitalize(this.$t('title.success')),

                        message: this.$filters.capitalize(this.$t('title.updated-success')),

                        time: 3000,

                        group: 'order'

                    });

            } else {

                this.$flashMessage.show({

                        type: 'error',

                        title: this.$filters.capitalize(this.$t('title.failure')),

                        message: this.$filters.capitalize(this.$t('title.updated-failure')),

                        time: 3000,

                        group: 'order'

                    });

            }

        },

        async getStatus() {

            let res = await listStatus(this.$route.params.id);

            if (res != null && res.status == 200) {

                this.listStatus = res.data;

            }

        }

    }

};

</script>

<style>

</style>