<template>
    <!-- Page Header Start -->
    <div class="container-fluid my-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container">
            <h1 class="display-6 animated slideInDown">{{ $filters.capitalize($t('title.order')) }}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a class="text-body" href="/">{{ $filters.capitalize($t('title.home')) }}</a></li>
                    <li class="breadcrumb-item text-dark active" aria-current="page">{{ $filters.capitalize($t('title.order')) }}</li>
                </ol>
            </nav>
        </div>
    </div>
     <!-- About Start -->
     <div class="container-fluid bg-icon mt-5">
        <div class="container">
            <div class="row align-items-center">
                <form-search :change-keyword="changeKeyword" :keywords="search.keywords" :on-refresh="onRefresh" :on-search="onSearch" />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{ $filters.capitalize($t('order.order-code')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('title.name')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('order.receive-phone')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('order.receive-address')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('order.total-money')) }}</th>
                                <th scope="col">{{ $filters.capitalize($t('order.order-date')) }}</th>
                                <th scope="col">#</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in orders" v-bind:key="index" @click="clickRow(item.id)">
                            <th scope="row">{{ index+1 }}</th>
                            <td>{{ item.code }}</td>
                            <td>{{ item.user.name }}</td>
                            <td>{{ item.receive_phone }}</td>
                            <td>{{ item.ship_address }}</td>
                            <td>{{ item.total_money }}</td>
                            <td>{{ (new Date(item.order_date * 1000)).toLocaleDateString() +' ' + (new Date(item.order_date * 1000)).toLocaleTimeString() }}</td>
                            <td>
                                <a class="btn btn-link text-primary px-3 mb-0" :href="'/manager/order/'+item.id.toString()">
                                    <i class="fas fa-pencil-alt text-danger me-2" aria-hidden="true"></i>{{ $filters.capitalize($t('action.detail')) }}
                                </a>
                                <a class="btn btn-link text-primary px-3 mb-0" href="javascript:;" @click="showModelDelete(item.id)">
                                    <i class="fas fa-trash text-danger me-2" aria-hidden="true"></i>{{ $filters.capitalize($t('action.delete')) }}
                                </a>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                            <pagination-index :page="search.page" :total="search.total" :page-size="search.pagesize" :change-page="changePage" :next-page="nextPage" :prev-page="prevPage" />
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <confirm-delete :ok="agreeDelete" :cancel="closeDelete" :show="show" /> 
</template>
<script>
import { list, del } from '@/api/order';
import PaginationIndex from '../components/Pagination.vue'
import FormSearch from './components/search.vue'
import ConfirmDelete from '../../components/ConfirmDelete.vue'
export default {
    name: "OrderIndex",
    data(){
        return {
            isLoading: false,
            show: false,
            delete_id: 0,
            orders: [],
            search: {
                keywords: '',
                total: 0,
                page: 1,
                pagesize: 5,
            },
        }
    },
    components: {
        PaginationIndex,
        FormSearch,
        ConfirmDelete
    },
    async created(){
        var params = this.$router.currentRoute.value.fullPath.split('?');
        var query = '';
        if (params.length > 1) {
        query = params.slice(-1);
        }
        this.getOrders(query);
        var currentQuery = this.$router.currentRoute.value.query;
        if (currentQuery.keywords != undefined) {
        this.search.keywords = currentQuery.keywords;
        }
    },
    watch: {
        '$route'(to, from){
            console.log(from);
            var params = to.fullPath.split('?');
            var query = '';
            if (params.length > 1) {
                query = params.slice(-1);
            }
            this.getOrders(query);
        }
    },
    methods:{
        async getOrders(query) {
            let res = await list(query);
            console.log(res);
            if (res != null && res.status == 200 && res.data != null) {
                this.orders = res.data.data;
                this.search.total = res.data.total;
                this.search.page = res.data.page;
                this.search.pagesize = res.data.pagesize;
            }
        },
        changePage(value){

            if (this.search.page != value) {

                this.search.page = value;

                this.$router.push({ path: '/manager/order', query: this.search });

            }

        },

        nextPage(){

            this.search.page = Number(this.search.page)+1;

            this.$router.push({ path: '/manager/order', query: this.search });

        },

        prevPage(){

            this.search.page = Number(this.search.page)-1;

            this.$router.push({ path: '/manager/order', query: this.search });

        },

        changeKeyword(e) {

            this.search.keywords = e.target.value;

        },

        async onSearch(){

            if (this.search.keywords.trim() != '') {

                this.$router.push({ path: '/manager/order', query: this.search });

            }

        },

        onRefresh(){

            this.search.keywords = '';

            this.$router.push({ path: '/manager/order' });

        },

        async agreeDelete(){

            this.show = false;

            if (this.delete_id > 0){

                let res = await del(this.delete_id);

                if (res != null && res.status == 200) {

                    for(var i = 0; i < this.orders.length; i++) {

                        if (this.orders[i].id == this.delete_id) {

                            this.orders.splice(i, 1);

                            break;

                        }

                    }

                    this.$flashMessage.show({

                        type: 'success',

                        title: this.$filters.capitalize(this.$t('title.success')),

                        message: this.$filters.capitalize(this.$t('title.delete-success')),

                        time: 3000,

                        group: 'order'

                    });

                } else {

                    this.$flashMessage.show({

                        type: 'error',

                        title: this.$filters.capitalize(this.$t('title.failure')),

                        message: this.$filters.capitalize(this.$t('title.delete-failure')),

                        time: 3000,

                        group: 'order'

                    });

                }

            }

        },

        closeDelete(){

            this.show = false;

            this.delete_id = 0;

        },

        showModelDelete(id){

            this.delete_id = id;

            this.show = true;

        },
        clickRow(id) {
            this.$router.push({ path: '/manager/order/'+id });
        }
    }

};

</script>