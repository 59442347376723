<template>
    <img v-if="item.feature_image != null" class="img-fluid" :src="host_url + item.feature_image" alt="No image" >
    <div class="bg-light p-1">
        <a class="d-block h6 text-body lh-base mb-4" :href="'/short-video/chi-tiet/'+item.slug">{{ item.name }}</a>
        <div class="text-muted border-top pt-4">
            <small class="me-3" style="font-size : 0.6vw;"><i class="fa fa-user text-primary me-2"></i>{{ item.author }}</small>
            <small class="me-3 text-break" style="font-size : 0.6vw;"><i class="fa fa-calendar text-primary me-2"></i>{{ (new Date(item.updated_at)).toLocaleString() }}</small>
        </div>
    </div>
</template>
<script>
import { api_backend } from '@/constants/configs';
export default {
    name: "VideoItem",
    data(){
        return {
            host_url: process.env.VUE_APP_BASE_HOST,
            backend: api_backend,
        };
    },
    props: [
        'item'
    ]
}
</script>