import request from '@/utils/request'
function add(data){
    return request({
        url: '/order',
        method: 'post',
        data
    });
}
function list(query){
    return request({
        url: '/order?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/order/'+id,
        method: 'get'
    });
}
function del(id){
    return request({
        url: '/order/'+id,
        method: 'delete'
    });
}
function edit(id, status){
    return request({
        url: '/order/'+id+'/'+status,
        method: 'put'
    });
}
function bookService(data){
    return request({
        url: '/order-service',
        method: 'post',
        data
    });
}
export {list, detail, add, del, edit, bookService};